/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, ReactNode, useState } from 'react';
import Card, { CardBody, CardHeader, CardHeaderChild } from '../ui/Card';
import Icon from '../icon/Icon';
import Modal from '../ui/Modal';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../ui/Dropdown';
import Button from '../ui/Button';
import { TIcons } from '../../types/icons.type';

interface IChartContainer {
	title: string;
	icon: string;
	children: ReactNode;
	isSampleData?: boolean;
	isDynamicChart?: boolean;
	chartType?: TChartType;
	setChartType?: React.Dispatch<React.SetStateAction<TChartType>>;
	onExportWrapper?: (exportType: 'csv' | 'pdf') => void;
}

export type TChartType = 'bar' | 'line' | 'area';
const AVAILABLE_CHARTS: {
	[key in TChartType]: { key: TChartType; text: string; icon: TIcons };
} = {
	bar: { key: 'bar', text: 'Bar Chart', icon: 'HeroChartBarSquare' },
	line: { key: 'line', text: 'Line Chart', icon: 'HeroPresentationChartLine' },
	area: { key: 'area', text: 'Area Chart', icon: 'HeroCube' },
};

const ChartContainer: FC<IChartContainer> = ({
	title,
	icon,
	children,
	isSampleData,
	isDynamicChart = false,
	chartType = 'bar',
	setChartType = () => {},
	onExportWrapper,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	function getHeader() {
		return (
			<>
				<div className='mr-2'>
					<Icon icon={icon} size='text-2xl' />
				</div>
				<div className='flex flex-grow justify-between'>
					<div className='flex justify-between'>
						<div>
							<div className='text-amber-500'>{title}</div>
							{/* <div className='text-lg text-zinc-500'>{subTitle}</div> */}
						</div>
						{isSampleData && <div className='ml-5 italic'>Sample Data</div>}
					</div>
					<div className='flex text-right'>
						{(isDynamicChart || onExportWrapper) && (
							<Dropdown className='ml-2'>
								<DropdownToggle hasIcon={false}>
									<Button
										// variant='outline'
										color='zinc'
										icon='HeroEllipsisVertical'
									/>
								</DropdownToggle>
								<DropdownMenu placement='bottom-end'>
									{isDynamicChart && (
										<Dropdown>
											<DropdownToggle>
												<DropdownItem
													color='zinc'
													className='border-b-2'
													icon={AVAILABLE_CHARTS[chartType].icon}>
													{AVAILABLE_CHARTS[chartType].text}
												</DropdownItem>
											</DropdownToggle>
											<DropdownMenu placement='bottom-end'>
												{Object.values(AVAILABLE_CHARTS).map((item) => (
													<DropdownItem
														key={item.key}
														isActive={chartType === item.key}
														onClick={() => setChartType(item.key)}
														icon={item.icon}>
														{item.text}
													</DropdownItem>
												))}
											</DropdownMenu>
										</Dropdown>
									)}
									{onExportWrapper && (
										<>
											<DropdownItem
												onClick={() => onExportWrapper('csv')}
												icon='HeroTableCells'>
												Export CSV
											</DropdownItem>
											<DropdownItem
												onClick={() => onExportWrapper('pdf')}
												icon='HeroDocument'>
												Export PDF
											</DropdownItem>
										</>
									)}
								</DropdownMenu>
							</Dropdown>
						)}
						<button
							className='ml-2'
							type='button'
							onClick={() => setIsExpanded(!isExpanded)}>
							<Icon
								icon={isExpanded ? 'HeroXMark' : 'HeroArrowsPointingOut'}
								size='text-2xl'
							/>
						</button>
					</div>
				</div>
			</>
		);
	}
	return (
		<>
			<Card>
				<CardHeader>
					<CardHeaderChild className='w-full'>
						<div className='flex w-full'>{getHeader()}</div>
					</CardHeaderChild>
				</CardHeader>
				<CardBody>{children}</CardBody>
			</Card>
			<Modal
				isScrollable={false}
				isOpen={isExpanded}
				setIsOpen={setIsExpanded}
				size='2xl'
				isCentered>
				<CardHeader>
					<CardHeaderChild className='w-full'>
						<div className='flex w-full'>{getHeader()}</div>
					</CardHeaderChild>
				</CardHeader>
				<CardBody style={{ height: '400px', overflowY: 'auto' }}>{children}</CardBody>
			</Modal>
		</>
	);
};
ChartContainer.defaultProps = {
	isSampleData: true,
	isDynamicChart: false,
	chartType: 'bar',
	setChartType: () => {},
	onExportWrapper: undefined,
};
export default ChartContainer;
