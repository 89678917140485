import { ISignUp } from '../../pages/signUp/signUp';
import { setTokenUser } from '../../utils/common';
import { IOptions, PromiseAxiosResponse } from '../types';
import ApiCore from '../utils/apiCore';
import apiProvider from '../utils/apiProvider';

const BASE_URL = 'auth';

class ApiAuth extends ApiCore {
	// eslint-disable-next-line class-methods-use-this
	private createFormData(data: ILogin): URLSearchParams {
		const formData = new URLSearchParams();
		Object.entries(data).forEach(([key, value]) => {
			if (typeof value === 'string') {
				formData.append(key, value);
			}
		});
		return formData;
	}

	login: (data: ILogin) => PromiseAxiosResponse;

	signUp: (data: ISignUp) => PromiseAxiosResponse;

	constructor(params: IOptions) {
		super(params);
		this.login = (data: ILogin): PromiseAxiosResponse => {
			const formData = this.createFormData(data);
			const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
			return apiProvider
				.post(`${BASE_URL}/login`, formData, { headers })
				.then((res) => {
					setTokenUser(JSON.stringify(res.data));
					return res;
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.error('Login error:', error);
					throw error;
				});
		};

		this.signUp = (data: ISignUp): PromiseAxiosResponse => {
			return apiProvider.post(`${BASE_URL}/register`, data).catch((error) => {
				// eslint-disable-next-line no-console
				console.error('Sign Up error:', error);
				throw error;
			});
		};
	}
}

export const apiAuth = new ApiAuth({
	fetchAll: false,
	fetchOne: false,
	post: false,
	put: false,
	patch: false,
	delete: false,
	url: BASE_URL,
	plural: '',
	single: '',
});

export interface ILogin {
	email: string;
	password: string;
}
