/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import { TooltipProps } from 'recharts';
import shortid from 'shortid';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import useDarkMode from '../../hooks/useDarkMode';
import { getToolTipStyles } from './utils';
import { calculatePercentage, compareDate, formatDate } from '../../utils/common';

export interface NewTooltipInterface<TValue extends ValueType, TName extends NameType>
	extends TooltipProps<TValue, TName> {
	active?: boolean;
	payload?: Array<Payload<TValue, TName>>;
}

const ComparisonToolTip: React.FC<NewTooltipInterface<ValueType, NameType>> = ({
	active,
	payload,
}) => {
	const { isDarkTheme } = useDarkMode();

	if (active && payload?.length) {
		const isComparisonAvailable = payload.length > 1;
		const value1 = payload[1]?.value ?? 0;
		const value2 = payload[0]?.value ?? 0;
		const difference = isComparisonAvailable
			? Number((Number(value1) - Number(value2)).toFixed(2))
			: 0;
		const differencePercentage = isComparisonAvailable
			? calculatePercentage(value1 as number, value2 as number)
			: 0;

		let arrow;
		if (difference > 0) {
			arrow = '↑';
		} else if (difference === 0) {
			arrow = '';
		} else {
			arrow = '↓';
		}

		return (
			<div style={getToolTipStyles(isDarkTheme)}>
				<div className='rounded-lg p-4 shadow-lg'>
					<div className='flex items-center justify-between'>
						<h2 className='text-lg font-semibold capitalize'>{payload[0].name}</h2>
						{isComparisonAvailable && (
							<div
								className={`${
									difference < 0 ? 'text-red-600' : 'text-green-600'
								} text-right`}>
								<div className='font-bold'>
									{arrow}
									{Math.abs(difference)}
								</div>
								<div>{Math.abs(differencePercentage)}%</div>
							</div>
						)}
					</div>
					<ul className='mt-3'>
						{payload.map((item, index) => (
							<li
								key={shortid.generate()}
								className='flex justify-between py-1 text-sm'>
								<span
									style={{ background: item.color }}
									className='mx-1 mt-1 h-1 w-1 p-1.5 align-bottom'
								/>
								<span className='font-medium'>
									{formatDate(
										item.payload[
											index === 0 && item.payload.hasOwnProperty('date')
												? 'date'
												: compareDate
										],
									)}
								</span>
								<span className='ms-4 font-bold'>{item.value}</span>
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	}
	return null;
};

ComparisonToolTip.defaultProps = {
	active: false,
	payload: [],
};

export default ComparisonToolTip;
