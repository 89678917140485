/* eslint-disable no-console */
import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { DefaultAxiosResponse } from '../types';
import { getAkompliceUsersValue, setTokenUser } from '../../utils/common';

let isRefreshing = false;
let refreshSubscribers: any[] = [];
function subscribeTokenRefresh(callback: any) {
	refreshSubscribers.push(callback);
}

function onRefresh(token: any) {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
	refreshSubscribers.map((callback) => callback(token));
	refreshSubscribers = [];
}
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
	_retry?: boolean;
}
interface CustomAxiosError<T = unknown, D = any> extends AxiosError<T, D> {
	config?: CustomAxiosRequestConfig;
}

export default function createAxiosInstance(baseURL: string): AxiosInstance {
	const instance = axios.create({
		baseURL,
		headers: {
			'Content-Type': 'application/json',
		},
	});

	async function refreshToken() {
		const token = getAkompliceUsersValue('refresh_token') as string;
		const user = getAkompliceUsersValue('user') as string;

		if (!token || !user) {
			throw new Error('No refresh token or user identifier available.');
		}
		try {
			const response = await instance.post(`/auth/refresh`, { token, user });
			return response; // Adjust according to your API's response structure
		} catch (error) {
			console.error('Error refreshing token:', error);
			throw new Error(error as string);
		}
	}

	// Request Interceptor for Token
	instance.interceptors.request.use(
		(config) => {
			if (config?.url && !config?.url.startsWith('/auth/')) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const accessToken = getAkompliceUsersValue('access_token');
				if (accessToken) {
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					config.headers.Authorization = `Bearer ${accessToken}`;
				}
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		},
	);

	instance.interceptors.response.use(
		(response: DefaultAxiosResponse) => {
			if (response.status >= 400) {
				throw new Error(response.data as string);
			}
			return response;
		},
		async (error: CustomAxiosError) => {
			const originalRequest = error.config;
			console.log('CustomAxiosError');
			if (
				error.response?.status === 401 &&
				originalRequest?.headers &&
				!originalRequest._retry &&
				!isRefreshing
			) {
				isRefreshing = true;
				originalRequest._retry = true;

				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				await refreshToken()
					.then((res) => {
						setTokenUser(JSON.stringify(res.data));
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
						onRefresh(res.data.access_token);
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					})
					.catch((err) => {
						isRefreshing = false;
						refreshSubscribers = [];
						return Promise.reject(err);
					});

				return new Promise((resolve) => {
					subscribeTokenRefresh((token: any) => {
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						originalRequest.headers.Authorization = `Bearer ${token}`;
						resolve(instance(originalRequest));
					});
				});
			}

			// Handle other errors
			if (error.response) {
				console.log('Error Status:', error.response.status);
				// Handle HTTP errors
			} else if (error.request) {
				console.log(instance);
				console.log(error.request);
				console.log('The request was made but no response was received');
				// Handle network or other technical issues
			} else {
				console.log('Error setting up the request:', error.message);
			}
			return Promise.reject(error);
		},
	);

	return instance;
}
