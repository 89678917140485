/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import _ from 'lodash';

/* eslint-disable no-plusplus */
export function getRandomColor() {
	return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}
export function dummy() {
	return 'dummy';
}

export function getAkompliceUsersValue(propertyType: string) {
	const storedData = localStorage.getItem('akomplice_user');
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
	return storedData ? JSON.parse(storedData)?.[propertyType] : undefined;
}

export function setTokenUser(data: string) {
	localStorage.setItem('akomplice_user', data);
}

export function sumMetricsByKey(metrics: any[], keys: string[]): Record<string, number> {
	const sums: Record<string, number> = {};
	// eslint-disable-next-line no-return-assign
	keys.forEach((key) => (sums[key] = 0));

	metrics?.forEach((metric) => {
		keys.forEach((key) => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (key in metric && typeof metric[key] === 'number') {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				sums[key] += metric[key];
			}
		});
	});

	Object.keys(sums).forEach((key) => {
		if (Object.hasOwn(sums, key)) {
			sums[key] = parseFloat(sums[key].toFixed(2));
		}
	});
	Object.keys(sums).forEach((key) => {
		sums[key] = parseFloat(sums[key].toFixed(2));
	});

	return sums;
}

export function averageMetricsByKey(metrics: any[], keys: string[]): Record<string, number> {
	const averages: Record<string, number> = {};
	const counts: Record<string, number> = {};

	keys.forEach((key) => {
		averages[key] = 0;
		counts[key] = 0;
	});

	metrics?.forEach((metric) => {
		keys.forEach((key) => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (key in metric && typeof metric[key] === 'number') {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				averages[key] += metric[key];
				// eslint-disable-next-line no-plusplus
				counts[key]++;
			}
		});
	});

	Object.keys(averages).forEach((key) => {
		if (counts[key] > 0) {
			const avg = averages[key] / counts[key];
			averages[key] = parseFloat(avg.toFixed(avg > 1 ? 0 : 2));
		} else {
			averages[key] = 0;
		}
	});

	return averages;
}

type KeyMappings = {
	[key: string]: string;
};

export function renameKeys(data: any[], keyMappings: KeyMappings): any {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return data?.map((item) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const newItem: any = { ...item };
		// eslint-disable-next-line no-restricted-syntax
		for (const [oldKey, newKey] of Object.entries(keyMappings)) {
			if (oldKey in newItem) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
				newItem[newKey] = newItem[oldKey];
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				delete newItem[oldKey];
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return newItem;
	});
}

export const colorClasses = ['text-blue-500', 'text-green-500', 'text-cyan-500', 'text-orange-500'];
export const myColors = [
	'#3b82f6',
	'#3bf658',
	'#55bab6',
	'#ff7300',
	'#9C27B0',
	'#3F51B5',
	'#FFC107',
];
// export const myColors = ['#3b82f6', '#3bf658', '#55bab6', '#ff7300'];
export const myColorsNew = ['#3b82f6', '#3bf658', '#55bab6', '#ff7300'];

// eslint-disable-next-line @typescript-eslint/no-shadow
export const getLastNDays = (fromDate: Date, toDate: Date): string => {
	const oneDay = 24 * 60 * 60 * 1000;
	const daysDifference = Math.abs((toDate.getTime() - fromDate.getTime()) / oneDay) + 1;
	return `Last ${daysDifference} Days`;
};

export function getFormattedDate(startDate: Date, endDate: Date): string {
	const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
	const formattedStartDate: string = startDate.toLocaleDateString('en-US', options);
	const formattedEndDate: string = endDate.toLocaleDateString('en-US', options);
	return `${formattedStartDate} - ${formattedEndDate}`;
}

export interface Item {
	[key: string]: any;
}

interface Metrics {
	[index: number]: Item[];
}

const compareKeyword = 'Compared';
export const compareDate = `${compareKeyword} date`;
export const combineData = (metrics: Metrics): Item[] => {
	const firstItems = metrics[1];
	const secondItems = metrics[0];
	const combinedItems: Item[] = [];
	const maxLength = Math.max(firstItems?.length, secondItems?.length);

	for (let i = 0; i < maxLength; i++) {
		const newItem: Item = {};

		const firstItem = firstItems[i];
		const secondItem = secondItems[i];

		if (firstItem) {
			Object.keys(firstItem).forEach((key) => {
				newItem[key] = firstItem[key];
			});
		}

		if (secondItem) {
			Object.keys(secondItem).forEach((key) => {
				newItem[`${compareKeyword} ${key}`] = secondItem[key];
			});
		}

		combinedItems.push(newItem);
	}

	return combinedItems;
};

export const getData = (data: Metrics | Item[], isCompare = false): any => {
	return isCompare ? combineData(data as Metrics) : data;
};

export const getKeys = (dataKeys: string[], isCompare = false) => {
	const compareKey = `${compareKeyword} ${dataKeys[0]}`;
	const newKeys = _.cloneDeep(dataKeys);
	if (isCompare && !newKeys.includes(compareKey)) {
		newKeys.push(compareKey);
	}
	return newKeys;
};

export const calculatePercentage = (value1: number, value2: number) => {
	if (value2 === 0) {
		return -100;
	}
	return Math.round(((value2 - value1) / value2) * 100);
};

export const formatDate = (dateString: string): string => {
	const date = new Date(dateString);
	return date.toLocaleDateString('en-US', {
		weekday: 'short',
		day: '2-digit',
		month: 'short',
		year: 'numeric',
	});
};

export function formatMapData(
	data: any,
	metric1: string,
	metric2: string,
	firstItem: [string, string] = ['Country', 'Popularity'],
) {
	const newMapData = [firstItem];
	data.forEach((x: any) => {
		if (x?.[metric1] && x?.[metric2]) {
			newMapData.push([x[metric1], x[metric2]]);
		}
	});
	return newMapData;
}

type MetricsItem = {
	[key: string]: any;
};

export function flattenNestedObjects(data: MetricsItem[]): MetricsItem[] {
	return data.map((item: MetricsItem): MetricsItem => {
		Object.keys(item).forEach((key) => {
			try {
				const parsed = JSON.parse(item[key]);
				if (typeof parsed === 'object' && parsed !== null) {
					delete item[key]; // Delete the original property
					Object.keys(parsed).forEach((nestedKey) => {
						const newKey = `${key}_${nestedKey.replaceAll(' ', '_')}`;
						item[newKey] = parsed[nestedKey];
					});
				}
			} catch (e) {}
		});
		return item;
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generatePdfUrl(data: any, fileName: string, type: string) {
	const pdfData = new Blob([data], { type });
	const pdfUrl = URL.createObjectURL(pdfData);
	const downloadLink = document.createElement('a');
	downloadLink.href = pdfUrl;
	downloadLink.download = fileName;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
	URL.revokeObjectURL(pdfUrl);
}

export async function fetchImageAsBlob(imageUrl: string): Promise<Blob> {
	try {
		const response = await fetch(imageUrl, {
			mode: 'cors',
		});
		const blob = await response.blob();
		return blob;
	} catch (error) {
		console.error('Error fetching image as blob:', error);
		throw new Error('Failed to fetch image');
	}
}
export async function toBlob(url: string): Promise<Blob> {
	return new Promise<Blob>((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		xhr.onload = () => {
			if (xhr.status === 200) {
				resolve(xhr.response);
			} else {
				reject(new Error('Failed to retrieve image'));
			}
		};
		xhr.onerror = () => {
			reject(new Error('Failed to retrieve image'));
		};
		xhr.open('GET', url);
		xhr.send();
	});
}
