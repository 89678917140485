import React from 'react';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Subheader, { SubheaderLeft } from '../components/layouts/Subheader/Subheader';
import PieLineChart from '../components/chart_containers/PieLineChart';
import LineChartWithLegends from '../components/chart_containers/LineChartWithLegends';
import OnlyLineChart from '../components/chart_containers/OnlyLineChart';
import BarChartContainer from '../components/chart_containers/BarChartContainer';
import TableChartWithContainer from '../components/chart_containers/TableChartWithContainer';

const pieData = [
	{ name: 'Group A', value: 400 },
	{ name: 'Group B', value: 300 },
	{ name: 'Group C', value: 300 },
	{ name: 'Group D', value: 200 },
];

const pieData2 = [
	{ name: 'Category X', value: 250 },
	{ name: 'Category Y', value: 450 },
	{ name: 'Category Z', value: 150 },
	{ name: 'Category W', value: 300 },
];

const pieData3 = [
	{ name: 'Type 1', value: 600 },
	{ name: 'Type 2', value: 350 },
	{ name: 'Type 3', value: 200 },
	{ name: 'Type 4', value: 450 },
];

const dataSet1 = [
	{ name: '2022-03-01', value: 100 },
	{ name: '2022-03-05', value: 200 },
	{ name: '2022-03-10', value: 150 },
	{ name: '2022-03-15', value: 250 },
	{ name: '2022-03-20', value: 250 },
	{ name: '2022-05-20', value: 450 },
	{ name: '2022-05-25', value: 450 },
	{ name: '2022-05-30', value: 300 },
	{ name: '2022-05-01', value: 200 },
	{ name: '2022-05-05', value: 300 },
	{ name: '2022-06-05', value: 300 },
	{ name: '2022-06-10', value: 450 },
];

const dataSet2 = [
	{ name: '2022-05-01', value: 200 },
	{ name: '2022-05-05', value: 300 },
	{ name: '2022-06-05', value: 500 },
	{ name: '2022-06-10', value: 450 },
	{ name: '2022-06-15', value: 600 },
	{ name: '2022-06-20', value: 550 },
	{ name: '2022-06-25', value: 700 },
	{ name: '2022-06-30', value: 650 },
	{ name: '2022-05-10', value: 250 },
	{ name: '2022-05-15', value: 350 },
	{ name: '2022-05-20', value: 300 },
	{ name: '2022-05-25', value: 450 },
	{ name: '2022-05-30', value: 400 },
];

const dataSet3 = [
	{ name: '2022-07-01', value: 300 },
	{ name: '2022-07-05', value: 400 },
	{ name: '2022-07-10', value: 350 },
	{ name: '2022-08-10', value: 550 },
	{ name: '2022-08-15', value: 700 },
	{ name: '2022-08-20', value: 650 },
	{ name: '2022-08-25', value: 800 },
	{ name: '2022-08-30', value: 750 },
	{ name: '2022-07-15', value: 450 },
	{ name: '2022-07-20', value: 400 },
	{ name: '2022-07-25', value: 550 },
	{ name: '2022-07-30', value: 500 },
	{ name: '2022-08-05', value: 600 },
];

const dataSet4 = [
	{ name: '2022-09-01', value: 400 },
	{ name: '2022-09-05', value: 500 },
	{ name: '2022-09-10', value: 450 },
	{ name: '2022-09-15', value: 550 },
	{ name: '2022-10-20', value: 750 },
	{ name: '2022-10-25', value: 900 },
	{ name: '2022-10-30', value: 850 },
	{ name: '2022-09-20', value: 500 },
	{ name: '2022-09-25', value: 650 },
	{ name: '2022-09-30', value: 600 },
	{ name: '2022-10-05', value: 700 },
	{ name: '2022-10-10', value: 650 },
	{ name: '2022-10-15', value: 800 },
];
const lineChartWithLegendData = [
	{
		name: 'Page H',
		uv: 2000,
		pv: 1500,
		amt: 1000,
	},
	{
		name: 'Page I',
		uv: 2700,
		pv: 2200,
		amt: 1200,
	},
	{
		name: 'Page J',
		uv: 3000,
		pv: 2500,
		amt: 2000,
	},
	{
		name: 'Page K',
		uv: 3200,
		pv: 2000,
		amt: 2500,
	},
	{
		name: 'Page L',
		uv: 4230,
		pv: 3100,
		amt: 3500,
	},
	{
		name: 'Page M',
		uv: 4530,
		pv: 3000,
		amt: 2300,
	},
	{
		name: 'Page N',
		uv: 5000,
		pv: 4500,
		amt: 4000,
	},
];
const Seo = () => {
	return (
		<PageWrapper name='Seo + Content Marketing'>
			<Subheader>
				<SubheaderLeft>
					<span>Seo + Content Marketing</span>
				</SubheaderLeft>
			</Subheader>
			<div className='w-full p-4 '>
				<div className='grid grid-cols-1 gap-4 md:grid-cols-4'>
					<PieLineChart
						title='Seo Attribution: Total Leads'
						icon='HeroBeaker'
						pieData={pieData}
						lineData={dataSet1}
						dataKeys={['value']}
					/>
					<PieLineChart
						title='Seo Attribution: Wanted Leads'
						icon='HeroBeaker'
						pieData={pieData2}
						lineData={dataSet2}
						dataKeys={['value']}
					/>
					<PieLineChart
						title='Seo Attribution: Sign-Ups'
						icon='HeroBeaker'
						pieData={pieData3}
						lineData={dataSet3}
						dataKeys={['value']}
					/>
					<LineChartWithLegends
						title='Seo Attribution: Historical'
						icon='HeroBeaker'
						lineData={lineChartWithLegendData}
						dataKeys={['uv', 'pv', 'amt']}
					/>
					<OnlyLineChart
						title='Keywords In Top 3'
						icon='HeroBeaker'
						lineData={lineChartWithLegendData}
						yDataKey='amt'
					/>
					<OnlyLineChart
						title='Keywords In Top 10'
						icon='HeroBeaker'
						lineData={dataSet4}
						yDataKey='value'
					/>
					<OnlyLineChart
						title='Keywords In Top 20'
						icon='HeroBeaker'
						lineData={dataSet1}
						yDataKey='value'
					/>
					<BarChartContainer
						data={dataSet1}
						title='Keywords In Top 100'
						icon='HeroBeaker'
						dataKey='value'
						xDataKey='name'
					/>
				</div>
				<div className='mt-4 grid grid-cols-1 gap-4 md:grid-cols-3'>
					<TableChartWithContainer title='Keywords Ranking' icon='HeroBeaker' />
					<BarChartContainer
						data={dataSet3}
						title='Historical Rankings'
						icon='HeroBeaker'
						dataKey='value'
						xDataKey='name'
					/>
					<OnlyLineChart
						title='Visibility'
						icon='HeroBeaker'
						lineData={dataSet2}
						dataKeys={['value']}
					/>
				</div>
			</div>
		</PageWrapper>
	);
};

export default Seo;
