import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/icon/Icon';
import useAsideStatus from '../../../../hooks/useAsideStatus';
import LogoTemplate from '../../Logo/Logo.template';

const LogoAndAsideTogglePart = () => {
	const { asideStatus, setAsideStatus } = useAsideStatus();
	return (
		<>
			<Link to='/' aria-label='Logo'>
				<LogoTemplate />
			</Link>
			<button
				type='button'
				aria-label='Toggle Aside Menu'
				onClick={() => setAsideStatus(!asideStatus)}
				className='flex h-12 w-12 items-center justify-center'>
				<Icon
					icon={asideStatus ? 'HeroArrowLeft' : 'HeroArrowRight'}
					className={`${asideStatus ? 'text-2xl' : ''}`}
				/>
			</button>
		</>
	);
};

export default LogoAndAsideTogglePart;
