import { IOptions, ITrackerData, PromiseAxiosResponse } from '../types';
import ApiCore from '../utils/apiCore';
import apiProvider from '../utils/apiProvider';

const url = 'pixels/';
const plural = '';
const single = '';

class ApiPixelTracking extends ApiCore {
	getTrackers: () => PromiseAxiosResponse;

	createTracker: (data: ITrackerData) => PromiseAxiosResponse;

	updateTracker: (data: ITrackerData, trackerID: string) => PromiseAxiosResponse;

	getTrackerById: (trackerId: string) => PromiseAxiosResponse;

	getTrackerEvents: () => PromiseAxiosResponse;

	deletePixelTrackerById: (trackerId: string) => PromiseAxiosResponse;

	constructor(params: IOptions) {
		super(params);

		this.getTrackers = () => {
			const resource = 'trackers';
			return apiProvider.fetchAll(`${url}${resource}`);
		};

		this.createTracker = (data: ITrackerData) => {
			const resource = 'trackers';
			return apiProvider.post(`${url}${resource}`, data); // Use POST method to create a new tracker
		};

		this.updateTracker = (data: ITrackerData, trackerID: string) => {
			const resource = `trackers/${trackerID}`;
			return apiProvider.put(`${url}${resource}`, data); // Use POST method to create a new tracker
		};

		this.getTrackerById = (trackerId: string) => {
			const resource = `trackers`;
			return apiProvider.fetchOne(`${url}${resource}`, trackerId);
		};

		this.getTrackerEvents = () => {
			const resource = 'events';
			return apiProvider.fetchAll(`${url}${resource}`);
		};

		this.deletePixelTrackerById = (trackerId: string) => {
			const resource = `pixel-trackers`;
			return apiProvider.remove(`${url}${resource}`, trackerId);
		};
	}
}

export const apiPixelTracking = new ApiPixelTracking({
	plural,
	single,
	url,
	delete: false,
	fetchAll: false,
	fetchOne: false,
	patch: false,
	post: false,
	put: false,
});

export const dummy = '';
