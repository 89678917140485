import * as Yup from 'yup';

export interface ISignUp {
	email: string;
	password: string;
	is_active: boolean;
	is_superuser: boolean;
	is_verified: boolean;
	first_name: string;
	last_name: string;
}

export const signUpSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email format').required('Email is required'),
	password: Yup.string().required('Password is required'),
	first_name: Yup.string().required('First name is required'),
	last_name: Yup.string().required('Last name is required'),
});

export class SignupFormValues {
	email = '';

	password = '';

	is_active = true;

	is_superuser = false;

	is_verified = false;

	first_name = '';

	last_name = '';

	constructor(signup?: ISignUp) {
		if (signup) {
			this.email = signup.email || '';
			this.password = signup.password || '';
			this.is_active = signup.is_active || true;
			this.is_superuser = signup.is_superuser || false;
			this.is_verified = signup.is_verified || false;
			this.first_name = signup.first_name || '';
			this.last_name = signup.last_name || '';
		}
	}
}
