/* eslint-disable class-methods-use-this */
import { AxiosResponse } from 'axios';
import { AxiosConfig, IConfig, IOptions } from '../types';
import apiProvider from './apiProvider';

class ApiCore {
	fetchAll: (config?: AxiosConfig) => unknown = () => {
		throw new Error('fetchAll not initialized');
	};

	fetchOne: (id: string, config?: AxiosConfig) => unknown = () => {
		throw new Error('fetchOne not initialized');
	};

	post: (data: unknown, config: AxiosConfig) => Promise<AxiosResponse<unknown, unknown>> = () => {
		throw new Error('post not initialized');
	};

	put: (data: unknown, config: IConfig) => Promise<AxiosResponse<unknown, unknown>> = () => {
		throw new Error('put not initialized');
	};

	patch: (data: unknown, config: IConfig) => Promise<AxiosResponse<unknown, unknown>> = () => {
		throw new Error('patch not initialized');
	};

	delete: (id: string, config?: AxiosConfig) => unknown = () => {
		throw new Error('delete not initialized');
	};

	constructor(options: IOptions) {
		const { url } = options;
		if (options.fetchAll) {
			this.fetchAll = (config: AxiosConfig = {}) => {
				return apiProvider.fetchAll(url, config);
			};
		}

		if (options.fetchOne) {
			this.fetchOne = (id: string, config: AxiosConfig = {}) => {
				return apiProvider.fetchOne(url, id, config);
			};
		}

		if (options.post) {
			this.post = (data: unknown = {}, config: AxiosConfig = {}) => {
				return apiProvider.post(url, data, config);
			};
		}

		if (options.put) {
			this.put = (data: unknown = {}, config: IConfig = {}) => {
				return apiProvider.put(url, data, config);
			};
		}

		if (options.patch) {
			this.patch = (data: unknown = {}, config: IConfig = {}) => {
				return apiProvider.patch(url, data, config);
			};
		}

		if (options.delete) {
			this.delete = (id: string, config: AxiosConfig = {}) => {
				return apiProvider.remove(url, id, config);
			};
		}
	}
}

export default ApiCore;
