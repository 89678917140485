/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState } from 'react';
import _ from 'lodash';
import ChartContainer, { TChartType } from '../helper/ChartContainer';
import DynamicChart from '../charts/DynamicChart';
import LoaderDotsCommon from '../LoaderDots.common';
import {
	averageMetricsByKey,
	getData,
	getKeys,
	myColors,
	sumMetricsByKey,
} from '../../utils/common';

interface IDynamicChartProps {
	id: string;
	defaultChartType: TChartType;
	data: any[];
	title: string;
	height: number;
	xDataKey: string;
	icon: string;
	color?: string;
	dataKeys: string[];
	isLoading: boolean;
	isComparisonChart: boolean;
	isSampleData?: boolean;
	showTopLegends?: boolean;
	isTopLegendAvg?: boolean;
	onExport?: (exportType: 'csv' | 'pdf', fileName: string, fields: string, url?: string) => void;
	exportFileName?: string;
	exportFields?: string;
	url?: string;
}

const DynamicChartContainer: FC<IDynamicChartProps> = (props) => {
	const {
		id,
		color,
		data,
		dataKeys,
		defaultChartType,
		height,
		icon,
		isComparisonChart,
		isLoading,
		isSampleData = false,
		showTopLegends = false,
		isTopLegendAvg = false,
		title,
		xDataKey,
		onExport,
		exportFileName,
		exportFields,
		url,
	} = props;

	const [chartType, setChartType] = useState<TChartType>(defaultChartType);
	const newColors = _.cloneDeep(myColors);
	const formattedData = getData(data, isComparisonChart);
	const newKeys = getKeys(dataKeys, isComparisonChart);

	const legendHeight = showTopLegends ? 70 : 0;
	const chartHeight = height - legendHeight;

	if (color) {
		newColors.unshift(color);
	}

	function onExportWrapper(exportType: 'csv' | 'pdf') {
		if (onExport && exportFileName && exportFields) {
			onExport(exportType, exportFileName, exportFields, url);
		}
	}

	const renderTopLegends = () => {
		let computedLegends = isTopLegendAvg
			? averageMetricsByKey(formattedData, newKeys)
			: sumMetricsByKey(formattedData, newKeys);

		let total = 0;

		if (title === 'Engagements Overview') {
			formattedData.forEach((e: { [x: string]: string | number }) => {
				if (e[title]) {
					total += +e[title];
				}
			});
			computedLegends = { Average: computedLegends['Engagements Overview'], Total: total };
		}
		return (
			<div style={{ height: legendHeight }} className='flex justify-evenly'>
				{Object.entries(computedLegends).map(([key, value], i) => (
					<div key={key} style={{ display: 'flex' }}>
						<div key={key} className='p-1 text-center'>
							<div>
								<span>
									{isTopLegendAvg && title !== 'Engagements Overview' && 'Avg '}
									{key}
								</span>
							</div>
							<div style={{ color: newColors[i] }} className='text-2xl'>
								{value.toLocaleString('en-US')}
							</div>
						</div>
					</div>
				))}
			</div>
		);
	};

	return (
		<div>
			<ChartContainer
				isDynamicChart
				isSampleData={isSampleData}
				icon={icon}
				chartType={chartType}
				setChartType={setChartType}
				title={title}
				// eslint-disable-next-line react/jsx-no-bind
				onExportWrapper={onExport ? onExportWrapper : undefined}>
				{isLoading ? (
					<div
						style={{ height }}
						className='flex h-full w-full flex-col items-center justify-center'>
						<LoaderDotsCommon />
					</div>
				) : (
					<div className='h-full' id={id}>
						{showTopLegends && renderTopLegends()}
						<div style={{ height: chartHeight }}>
							<DynamicChart
								data={formattedData}
								type={chartType}
								xDataKey={xDataKey}
								color={color}
								dataKeys={newKeys}
								isComparisonChart={isComparisonChart}
							/>
						</div>
					</div>
				)}
			</ChartContainer>
		</div>
	);
};

DynamicChartContainer.displayName = 'DynamicChartContainer';

DynamicChartContainer.defaultProps = {
	color: '',
	isSampleData: false,
	showTopLegends: false,
	isTopLegendAvg: false,
	onExport: undefined,
	exportFields: '',
	exportFileName: '',
	url: '',
};

export default DynamicChartContainer;
