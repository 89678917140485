import React, { useContext, useState } from 'react';
import { Chart, ChartWrapperOptions, ReactGoogleChartEvent } from 'react-google-charts';
import ThemeContext from '../../context/themeContext';

interface GeoChartProps {
	data: Array<Array<string | number>>;
	width?: string;
	height?: number | string;
	chartEvents?: ReactGoogleChartEvent[];
	options?: ChartWrapperOptions['options'];
}

interface ErrorBoundaryProps {
	children: React.ReactNode;
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
	const [hasError, setHasError] = useState<boolean>(false);

	const handleError = (error: Error) => {
		// You can log the error or handle it in any way you prefer.
		console.error('Error caught by error boundary:', error);
		setHasError(true);
	};

	try {
		if (hasError) {
			// Render a fallback UI here when an error occurs.
			return <div>Something went wrong.</div>;
		}

		return children;
	} catch (error) {
		handleError(error as Error);
		return null;
	}
};

const GeoChart: React.FC<GeoChartProps> = ({
	data,
	width = '100%',
	height = '400px',
	chartEvents,
	options,
}) => {
	const { isDarkTheme } = useContext(ThemeContext);

	if (options && !options?.backgroundColor) {
		options.backgroundColor = isDarkTheme ? '#18181b' : '#fff';
	}
	return (
		<ErrorBoundary>
			{data && (
				<Chart
					chartEvents={chartEvents}
					chartType='GeoChart'
					width={width}
					height={height}
					data={data}
					options={{ ...options }}
					mapsApiKey={process.env.MAPS_API_KEY}
				/>
			)}
		</ErrorBoundary>
	);
};
GeoChart.defaultProps = {
	width: '100%',
	height: '400px',
	chartEvents: [],
	options: {},
};
export default GeoChart;
