import React from 'react';
import Aside, { AsideBody, AsideFooter, AsideHead } from '../../../components/layouts/Aside/Aside';
import LogoAndAsideTogglePart from './_parts/LogoAndAsideToggle.part';
import DarkModeSwitcherPart from './_parts/DarkModeSwitcher.part';
import { navPages } from '../../../config/pages.config';
import Nav, { NavCollapse, NavItem } from '../../../components/layouts/Navigation/Nav';
import UserTemplate from '../User/User.template';
import { useAuth } from '../../../context/authContext';
import { authRole } from '../../../constants';

const DefaultAsideTemplate = () => {
	const { userData } = useAuth();

	return (
		<Aside>
			<AsideHead>
				<LogoAndAsideTogglePart />
			</AsideHead>
			<AsideBody>
				<Nav>
					{authRole.admin.includes(userData?.role as string) && (
						<NavCollapse
							text={navPages.admin.text}
							to={navPages.admin.to}
							icon={navPages.admin.icon}>
							<NavItem {...navPages.admin.subPages.client.list} />
							<NavItem {...navPages.admin.subPages.user.list} />
							<NavItem {...navPages.admin.subPages.connector.list} />
							<NavItem {...navPages.admin.subPages.billing.list} />
						</NavCollapse>
					)}
					<NavItem
						text={navPages.attributionOverview.text}
						to={navPages.attributionOverview.to}
						icon={navPages.attributionOverview.icon}
					/>
					<NavItem
						text={navPages.websiteTraffic.text}
						to={navPages.websiteTraffic.to}
						icon={navPages.websiteTraffic.icon}
					/>
					<NavItem
						text={navPages.googleLocal.text}
						to={navPages.googleLocal.to}
						icon={navPages.googleLocal.icon}
					/>
					<NavItem
						text={navPages.payPerClick.text}
						to={navPages.payPerClick.to}
						icon={navPages.payPerClick.icon}
					/>
					<NavItem
						text={navPages.seoContentMarketing.text}
						to={navPages.seoContentMarketing.to}
						icon={navPages.seoContentMarketing.icon}
					/>
					<NavItem
						text={navPages.socialMedia.text}
						to={navPages.socialMedia.to}
						icon={navPages.socialMedia.icon}
					/>
					<NavItem
						text={navPages.ottStreaming.text}
						to={navPages.ottStreaming.to}
						icon={navPages.ottStreaming.icon}
					/>
					<NavItem
						text={navPages.offline.text}
						to={navPages.offline.to}
						icon={navPages.offline.icon}
					/>
					<NavItem
						text={navPages.tvRadio.text}
						to={navPages.tvRadio.to}
						icon={navPages.tvRadio.icon}
					/>
					<NavItem
						text={navPages.reviewsRatings.text}
						to={navPages.reviewsRatings.to}
						icon={navPages.reviewsRatings.icon}
					/>
					<NavItem
						text={navPages.pixelTracking.list.text}
						to={navPages.pixelTracking.list.to}
						icon={navPages.pixelTracking.list.icon}
					/>
				</Nav>
			</AsideBody>
			<AsideFooter>
				<UserTemplate />
				<DarkModeSwitcherPart />
			</AsideFooter>
		</Aside>
	);
};

export default DefaultAsideTemplate;
