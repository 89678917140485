/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState } from 'react';
import shortid from 'shortid';
import PageWrapper from '../../components/layouts/PageWrapper/PageWrapper';
import Subheader, { SubheaderLeft } from '../../components/layouts/Subheader/Subheader';
import Button from '../../components/ui/Button';
import PixelTracking from './pixelTracking.graphs';
import UserList from './Tracker.List.page';

const TABS: {
	[key in 'TrackerGraphs' | 'TrackerList']: React.ReactNode;
} = {
	TrackerGraphs: (
		<>
			{/* <Icon icon='CustomYoutube' className='m-auto mr-2' size='text-xl' /> */}

			<span>Tracking Graph</span>
		</>
	),
	TrackerList: (
		<>
			{/* <Icon icon='CustomYoutube' className='m-auto mr-2' size='text-xl' /> */}
			<span>Trackers List</span>
		</>
	),
};

const HeaderPixel = () => {
	const [activeTab, setActiveTab] = useState(TABS.TrackerGraphs);
	return (
		<PageWrapper name='Social Media'>
			<Subheader>
				<SubheaderLeft>
					{Object.values(TABS).map((i) => (
						<Button
							variant={i === activeTab ? 'solid' : undefined}
							key={shortid.generate()}
							isActive={i === activeTab}
							onClick={() => setActiveTab(i)}>
							{i}
						</Button>
					))}
				</SubheaderLeft>
			</Subheader>
			{activeTab === TABS.TrackerGraphs && <PixelTracking />}
			{activeTab === TABS.TrackerList && <UserList />}
		</PageWrapper>
	);
};

export default HeaderPixel;
