import { useState } from 'react';
import dayjs from 'dayjs';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import colors from 'tailwindcss/colors';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/ui/Dropdown';
import Button from '../../../components/ui/Button';
import themeConfig from '../../../config/theme.config';
import { useDateRange } from '../../../context/dateRangeContext';
import Checkbox from '../../../components/form/Checkbox';
import './style.css';

const DefaultHeaderTemplate = () => {
	const {
		selectedDate,
		setSelectedDate,
		fromDate,
		toDate,
		isCompare,
		setIsCompare,
		compareFromDate,
		compareToDate,
		isCalenderDisabled,
	} = useDateRange();
	const [selectedDateTemp, setSelectedDateTemp] = useState(selectedDate);
	const [isCompareTemp, setIsCompareTemp] = useState(isCompare);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	// const handleDateChanges = (item: RangeKeyDict) => {
	// 	setSelectedDateTemp({ ...selectedDateTemp, ...item });
	// };

	function areDatesNonOverlapping(startA: Date, endA: Date, startB: Date, endB: Date) {
		return endA < startB || endB < startA;
	}

	const handleDateChanges = (item: RangeKeyDict) => {
		const { selection, comparison } = item;

		if (!isCompareTemp) {
			setSelectedDateTemp({ ...selectedDateTemp, ...item });
			return true;
		}

		if (
			(comparison &&
				areDatesNonOverlapping(
					comparison.startDate!,
					comparison.endDate!,
					selectedDateTemp.selection.startDate!,
					selectedDateTemp.selection.endDate!,
				)) ||
			(selection &&
				areDatesNonOverlapping(
					selection.startDate!,
					selection.endDate!,
					selectedDateTemp.comparison.startDate!,
					selectedDateTemp.comparison.endDate!,
				))
		) {
			setSelectedDateTemp({ ...selectedDateTemp, ...item });
			return true;
		}
		toast.error('Comparison date cannot be overlapped with selected date');
		return false;
	};
	const handleApply = () => {
		setSelectedDate(selectedDateTemp);
		setIsCompare(isCompareTemp);
		setIsDropdownOpen(false);
	};

	const handleCancel = () => {
		setSelectedDateTemp(selectedDate);
		setIsDropdownOpen(false);
	};

	const handleChange = () => {
		setIsCompareTemp(!isCompareTemp);
	};

	function getDateRange(startDate: Date, endDate: Date) {
		const dates = [];
		const currentDate = new Date(startDate);

		// Loop through each day from start date to end date
		while (currentDate <= endDate) {
			// Add current date to the array
			dates.push(new Date(currentDate));
			// Move to the next day
			currentDate.setDate(currentDate.getDate() + 1);
		}

		return dates;
	}

	return (
		<Header className='py-3'>
			<HeaderLeft>
				<div>{/* Empty Div */}</div>
			</HeaderLeft>
			<HeaderRight className='h-14 flex-col justify-evenly gap-0'>
				{/* <DefaultHeaderRightCommon /> */}
				{!isCalenderDisabled && (
					<Dropdown isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen}>
						<DropdownToggle setIsOpen={setIsDropdownOpen}>
							<Button isDisable={isCalenderDisabled || false} icon='HeroCalendarDays'>
								<span className={isCompare ? 'text-blue-500' : ''}>
									{dayjs(fromDate).format('MMMM D')} -
									{dayjs(toDate).format('MMMM D, YYYY')}
								</span>
							</Button>
						</DropdownToggle>
						<DropdownMenu className='!p-0' isCloseAfterLeave={false}>
							<div>
								<DateRangePicker
									className='ak_datePicker'
									calendarFocus='forwards'
									preventSnapRefocus
									onChange={handleDateChanges}
									moveRangeOnFirstSelection={false}
									months={2}
									ranges={
										isCompareTemp
											? [
													selectedDateTemp.selection,
													selectedDateTemp.comparison,
												]
											: [selectedDateTemp.selection]
									}
									direction='horizontal'
									maxDate={new Date()}
									rangeColors={[
										colors[themeConfig.themeColor][themeConfig.themeColorShade],
										colors.emerald[themeConfig.themeColorShade],
										colors.amber[themeConfig.themeColorShade],
									]}
								/>
							</div>
							<div className='flex w-full'>
								<div className='rdrDefinedRangesWrapper flex justify-between border-t py-2'>
									<div className='ms-4 self-center'>Compare</div>
									<Checkbox
										label=''
										isInline
										id='optionA'
										variant='switch'
										onChange={handleChange}
										checked={isCompareTemp}
									/>
								</div>
								<div className='ms-4 self-center'>
									<Button
										onClick={handleApply}
										variant='solid'
										rounded='rounded-full'>
										Apply
									</Button>
									<Button onClick={handleCancel}>Cancel</Button>
								</div>
							</div>
						</DropdownMenu>
					</Dropdown>
				)}
				{isCompare && (
					<div className='text-green-500'>
						<span>
							Compared: {dayjs(compareFromDate).format('MMMM D')} -
							{dayjs(compareToDate).format('MMMM D, YYYY')}
						</span>
					</div>
				)}
			</HeaderRight>
		</Header>
	);
};

export default DefaultHeaderTemplate;
