/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import {
	BarChart as BarChartWrapper,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import shortid from 'shortid';
import useDarkMode from '../../hooks/useDarkMode';
import { getToolTipStyles } from './utils';
import ComparisonToolTip from './ComparisonToolTip';

interface BarChartProps {
	data: any[];
	width?: string;
	height?: number;
	color?: string;
	xDataKey: string;
	yDataKey: string;
	dataKeys?: string[];
	isComparisonChart?: boolean;
}

const BarChart: React.FC<BarChartProps> = ({
	data,
	width = '100%',
	height = 220,
	color = '#007bff',
	xDataKey,
	yDataKey,
	dataKeys = [],
	isComparisonChart = false,
}) => {
	const { isDarkTheme } = useDarkMode();

	const myColors = ['#3bf658', '#3b82f6', '#55bab6', '#ff7300', '#9C27B0', '#3F51B5', '#FFC107'];
	const renderBars = () => {
		if (dataKeys.length > 1) {
			return dataKeys.map((key, index) => {
				return (
					<Bar
						key={shortid.generate()}
						dataKey={key}
						fill={myColors[index]}
						strokeDasharray='5 5'
						strokeWidth={2}
					/>
				);
			});
		}
		return <Bar dataKey={yDataKey} fill={color} />;
	};

	return (
		<ResponsiveContainer width={width} height={height}>
			<BarChartWrapper data={data}>
				{!isComparisonChart && <XAxis dataKey={xDataKey} />}
				<YAxis />
				<Tooltip
					contentStyle={getToolTipStyles(isDarkTheme)}
					content={isComparisonChart ? <ComparisonToolTip /> : undefined}
				/>
				{!isComparisonChart && <Legend />}
				{renderBars()}
			</BarChartWrapper>
		</ResponsiveContainer>
	);
};

export default BarChart;
