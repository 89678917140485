/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	ResponsiveContainer,
	Text,
	TextProps,
	Tooltip,
	Legend,
	CartesianGrid,
} from 'recharts';
import useDarkMode from '../../hooks/useDarkMode';
import { getToolTipStyles } from './utils';

const CustomYAxisTick = (props: { x: any; y: any; payload: any }) => {
	const { x, y, payload } = props;
	console.log(payload);

	return (
		<Text x={x + 10} y={y} dy={-4} fill='#fff' textAnchor='start' verticalAnchor='middle'>
			{payload.value}
		</Text>
	);
};

interface HorizontalBarChartProps {
	data: any[];
	width?: string;
	height?: number;
	color?: string;
	nameDataKey: string;
	dataKey: string;
}

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
	data,
	width = '100%',
	height = 220,
	color = '#007bff',
	nameDataKey,
	dataKey,
}) => {
	const renderCustomBarLabel = (props: {
		x: any;
		y: any;
		width: any;
		height: any;
		index: any;
	}) => {
		// eslint-disable-next-line @typescript-eslint/no-shadow, react/prop-types
		const { index, width, x, y, height } = props;
		// Access the name from the data using the index
		const name = data[index][nameDataKey];

		return (
			<text
				x={x + width / 2}
				y={y + height / 2}
				fill='#fff'
				fontSize={8}
				textAnchor='start'
				alignmentBaseline='middle'>
				{name}
			</text>
		);
	};

	const { isDarkTheme } = useDarkMode();

	return (
		<ResponsiveContainer width={width} height={height}>
			<BarChart
				data={data}
				layout='vertical'
				barCategoryGap={1}
				margin={{ top: 5, right: 2, left: 0, bottom: 5 }}>
				<XAxis type='number' hide />
				<YAxis type='category' dataKey={nameDataKey} width={0} />
				<Tooltip contentStyle={getToolTipStyles(isDarkTheme)} />
				<Legend />
				<Bar dataKey={dataKey} fill={color} label={renderCustomBarLabel} />
			</BarChart>
		</ResponsiveContainer>
	);
};

export default HorizontalBarChart;
