import React, { FC } from 'react';
import ChartContainer from '../helper/ChartContainer';
import LineChart from '../charts/LineChart';
import './style.css';

interface IPieLineChart {
	lineData: any[];
	title: string;
	icon: string;
	dataKeys: string[];
}
const LineChartWithLegends: FC<IPieLineChart> = ({ lineData, icon, title, dataKeys }) => {
	return (
		<ChartContainer title={title} icon={icon}>
			<div className='flex h-64 w-full'>
				<div className='h-full w-1/4'>
					<div>
						<div className='my-3'>
							<div>
								<span className='relative mr-2 inline-flex h-3 w-3 rounded-full bg-green-500' />
								<span>Total Leads</span>
							</div>
							<div className='mr-4'>20202</div>
						</div>
						<div className='my-3'>
							<div>
								<span className='relative mr-2 inline-flex h-3 w-3 rounded-full bg-blue-500' />
								<span>Total Leads</span>
							</div>
							<div className='mr-4'>20202</div>
						</div>
						<div className='my-3'>
							<span className='relative mr-2 inline-flex h-3 w-3 rounded-full bg-red-500' />
							<span>Total Leads</span>
							<div className='mr-4'>20202</div>
						</div>
					</div>
				</div>
				<div className='w-9/12'>
					<LineChart dataKeys={dataKeys} data={lineData} width='100%' height={220} />
				</div>
			</div>
		</ChartContainer>
	);
};

export default LineChartWithLegends;
