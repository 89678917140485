import { FC } from 'react';
import ChartContainer from '../helper/ChartContainer';
import './style.css';
import KeywordRanking from '../charts/Keywordrank';

interface ITableChartWithContainer {
	title: string;
	icon: string;
}
const TableChartWithContainer: FC<ITableChartWithContainer> = ({ icon, title }) => {
	return (
		<ChartContainer title={title} icon={icon}>
			<div className='flex h-64 w-full'>
				<KeywordRanking />
			</div>
		</ChartContainer>
	);
};

export default TableChartWithContainer;
