import { toast } from 'react-toastify';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleError(error: any): void {
	// eslint-disable-next-line no-console
	console.error(error);
	toast.error('Something went wrong!', {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
}

export default handleError;
