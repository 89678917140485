/* eslint-disable */
import React, { useState, FC } from 'react';
import shortid from 'shortid';
import { PieChart as PieChartWrapper, Pie, ResponsiveContainer, Cell, Text, Tooltip, Legend } from 'recharts';


interface IDynamicPieChartProps {
	chartData: any[];
	colors?: string[];
	width?: string;
    height?: number;
	innerRadius?: number;
	outerRadius?: number;
	defaultActiveIndex?: number;
	title?: string;
	isCustomLabel?: boolean
}

const PieChart: FC<IDynamicPieChartProps> = ({
	chartData,
	colors=[
		"#FFB84D",
		"#3385FF",
		"#FFFF66",
		"#FF6666",
		"#4DFF88",
		"#66CC66",
		"#CCCCFF",
		"#99FFFF",
		"#FFCCCC",
		"#FFCC99",
		"#9999CC",
		"#CC9999" 
	  ],
	defaultActiveIndex,
	width,
    height,
	isCustomLabel = false,
	outerRadius,
}) => {
	const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
	const onPieEnter = (_: any, index: number) => setActiveIndex(index);


	const [opacity, setOpacity] = useState({
		uv: 1,
		pv: 1,
	  });

	  
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
	cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload
  }:any) => {
	const radius = outerRadius + 30; // Increase the radius to place labels outside
	const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
	const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
	return (
	  <Text
		x={x}
		y={y}
		fill={payload.fill}
		textAnchor={x > cx ? 'start' : 'end'}
		dominantBaseline="central"
		style={{ fontSize: '0.80rem' }} // Smaller text size for neatness
	  >
		{`${payload.name} (${payload.value}, ${payload?.percentage +'%'??''})`}
	  </Text>
	);
  };

	return (
		<ResponsiveContainer  width={'100%'} height={height}>
			<PieChartWrapper>
				{/* <Pie
					activeIndex={activeIndex}
					activeShape={(props) => renderActiveShape(props, total)}
					data={chartData}
					cx='50%'
					cy='50%'
					// innerRadius={innerRadius}
					// outerRadius={outerRadius}
					dataKey='value'
					onMouseEnter={onPieEnter}>
					{chartData.map((entry, index) => (
						<Cell key={shortid.generate()} fill={colors[index % colors.length]} />
					))}
				</Pie> */}
          		<Pie dataKey="value" 
				data={chartData} 
				onMouseEnter={onPieEnter} 
				activeIndex={activeIndex}
				label={isCustomLabel? renderCustomizedLabel: true}
				innerRadius={0} outerRadius={outerRadius}
				>
				  {chartData.map((entry, index) => (
						<Cell key={shortid.generate()}  fill={colors[index % colors.length]} >
							{index}
						</Cell>
					))}
					<Tooltip />
				</Pie>
			</PieChartWrapper>
		</ResponsiveContainer>
	);
};

PieChart.defaultProps = {
	colors: [
		"#FFB84D",
		"#3385FF",
		"#FFFF66",
		"#FF6666",
		"#4DFF88",
		"#66CC66",
		"#CCCCFF",
		"#99FFFF",
		"#FFCCCC",
		"#FFCC99",
		"#9999CC",
		"#CC9999" 
	  ],
	innerRadius: 0,
	outerRadius: 80,
	defaultActiveIndex: 0,
	title: 'Total',
	width: '100%',
    height: 220,
};
export default PieChart;
