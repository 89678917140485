/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import ChartContainer from '../helper/ChartContainer';
import LineChart from '../charts/LineChart';
import './style.css';
import LoaderDotsCommon from '../LoaderDots.common';
import { getData, getKeys } from '../../utils/common';

interface IPieLineChart {
	lineData: any[];
	title: string;
	icon: string;
	dataKeys?: string[];
	isSampleData?: boolean;
	height?: number;
	yDataKey?: string;
	xDataKey?: string;
	isLoading?: boolean;
	isComparisonChart?: boolean;
	color?: string;
	onExport?: (exportType: 'csv' | 'pdf', fileName: string, fields: string) => void;
	exportFileName?: string;
	exportFields?: string;
	id?: string;
}
const OnlyLineChart: FC<IPieLineChart> = ({
	lineData,
	icon,
	title,
	dataKeys = [],
	height,
	isSampleData,
	yDataKey,
	xDataKey,
	isLoading,
	isComparisonChart,
	color,
	onExport,
	exportFileName,
	exportFields,
	id = 'idd',
}) => {
	function onExportWrapper(exportType: 'csv' | 'pdf') {
		if (onExport && exportFileName && exportFields) {
			onExport(exportType, exportFileName, exportFields);
		}
	}

	const formattedData = getData(lineData, isComparisonChart);
	const newKeys = getKeys(dataKeys, isComparisonChart);
	return (
		<ChartContainer
			title={title}
			icon={icon}
			isSampleData={isSampleData}
			onExportWrapper={exportFileName ? onExportWrapper : undefined}>
			{isLoading ? (
				<div
					style={{ height }}
					className='flex h-full w-full flex-col items-center justify-center'>
					<LoaderDotsCommon />
				</div>
			) : (
				<div className='flex w-full' id={id}>
					<LineChart
						xDataKey={xDataKey}
						yDataKey={yDataKey}
						dataKeys={newKeys}
						data={formattedData}
						width='100%'
						height={height}
						isComparisonChart={isComparisonChart}
						color={color}
					/>
				</div>
			)}
		</ChartContainer>
	);
};

OnlyLineChart.defaultProps = {
	height: 220,
	isSampleData: true,
	yDataKey: '',
	xDataKey: '',
	dataKeys: [],
	isLoading: false,
	isComparisonChart: false,
	color: '#3b82f6',
	onExport: () => {},
	exportFields: '',
	exportFileName: '',
};

export default OnlyLineChart;
