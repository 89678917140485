/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import ChartContainer from '../helper/ChartContainer';
import './style.css';
import BarChart from '../charts/Barchart';
import HorizontalBarChart from '../charts/HorizontalBarChart';
import LoaderDotsCommon from '../LoaderDots.common';
import { getData, getKeys } from '../../utils/common';

interface IBarChart {
	data: any[];
	title: string;
	icon: string;
	xDataKey: string;
	dataKey: string;
	isHorizontal?: boolean;
	color?: string;
	barDataKeys?: string[];
	height?: number;
	isSampleData?: boolean;
	isLoading?: boolean;
	isComparisonChart?: boolean;
}
const BarChartContainer: FC<IBarChart> = ({
	data,
	icon,
	title,
	xDataKey,
	dataKey,
	isHorizontal,
	color,
	barDataKeys = [],
	height,
	isSampleData,
	isLoading,
	isComparisonChart,
}) => {
	const formattedData = getData(data, isComparisonChart);
	const newKeys = getKeys(barDataKeys, isComparisonChart);

	return (
		<ChartContainer title={title} isSampleData={isSampleData} icon={icon}>
			{isLoading ? (
				<div
					style={{ height }}
					className='flex h-full w-full flex-col items-center justify-center'>
					<LoaderDotsCommon />
				</div>
			) : (
				<div className='flex w-full'>
					{isHorizontal ? (
						<HorizontalBarChart
							data={data}
							dataKey={dataKey}
							nameDataKey={xDataKey}
							color='#3b82f6'
							height={height}
						/>
					) : (
						<BarChart
							data={formattedData}
							dataKeys={newKeys}
							xDataKey={xDataKey}
							yDataKey={dataKey}
							color={color}
							height={height}
							isComparisonChart={isComparisonChart}
						/>
					)}
				</div>
			)}
		</ChartContainer>
	);
};

BarChartContainer.defaultProps = {
	isHorizontal: false,
	color: '#3b82f6',
	barDataKeys: [],
	height: 220,
	isSampleData: true,
	isLoading: false,
	isComparisonChart: false,
};

export default BarChartContainer;
