import React, { lazy } from 'react';
import { authPages, navPages } from '../config/pages.config';
import NotFoundPage from '../pages/NotFound.page';
import LoginPage from '../pages/Login.page';
import ProfilePage from '../pages/Profile.page';
import SignUpPage from '../pages/signUp/SignUp.page';
import { authRole } from '../constants';
import { ProtectedRouteProps } from '../types';
import Seo from '../pages/Seo.page';
import PixelTracking from '../pages/pixelTracking/pixelTracking.graphs';
import HeaderPixel from '../pages/pixelTracking/PixelTracking.header';

// Admin Pages Start
const UserList = lazy(() => import('../pages/admin/user/UsersList.page'));
const UserPage = lazy(() => import('../pages/admin/user/UserForm.page'));

const ClientList = lazy(() => import('../pages/admin/client/ClientList.page'));
const ClientForm = lazy(() => import('../pages/admin/client/CLientForm.page'));

const ConnectorList = lazy(() => import('../pages/admin/connectors/ConnectorList.page'));
const ConnectorForm = lazy(() => import('../pages/admin/connectors/ConnectorForm.page'));

const BillingList = lazy(() => import('../pages/admin/billing/BillingList.page'));
const BillingForm = lazy(() => import('../pages/admin/billing/BillingForm.page'));
// Admin Pages END

const AttributionOverviewPage = lazy(() => import('../pages/AttributionOverview.page'));
const WebsiteTrafficPage = lazy(() => import('../pages/WebsiteTraffic.page'));
const GoogleLocalPage = lazy(() => import('../pages/GoogleLocal.page'));
const PayPerClickPage = lazy(() => import('../pages/PayPerClick.page'));
const SocialMediaPage = lazy(() => import('../pages/SocialMedia.page'));

const OfflineLeadsPage = lazy(() => import('../pages/OffLineLeads.page'));
const ReviewsRatingsPage = lazy(() => import('../pages/ReviewsRatings.page'));
const OTTPage = lazy(() => import('../pages/OTT.page'));
const TVRadio = lazy(() => import('../pages/TvRadio.page'));

const TrackerList = lazy(() => import('../pages/pixelTracking/Tracker.List.page'));
const TrackerForm = lazy(() => import('../pages/pixelTracking/Tracker.Form.page'));

const UnderConstructionPage = lazy(() => import('../pages/UnderConstruction.page'));

const contentRoutes: ProtectedRouteProps[] = [
	/**
	 * Admin::BEGIN
	 */
	{
		path: navPages.admin.subPages.user.list.to,
		element: <UserList />,
		roles: authRole.admin,
	},
	{
		path: `${navPages.admin.subPages.user.form.to}/:id`,
		element: <UserPage />,
		roles: authRole.admin,
	},
	{
		path: navPages.admin.subPages.client.list.to,
		element: <ClientList />,
		roles: authRole.admin,
	},
	{
		path: `${navPages.admin.subPages.client.form.to}/:id`,
		element: <ClientForm />,
		roles: authRole.admin,
	},
	{
		path: navPages.admin.subPages.connector.list.to,
		element: <ConnectorList />,
		roles: authRole.admin,
	},
	{
		path: `${navPages.admin.subPages.connector.form.to}/:id`,
		element: <ConnectorForm />,
		roles: authRole.admin,
	},
	{
		path: navPages.admin.subPages.connector.list.to,
		element: <ConnectorList />,
		roles: authRole.admin,
	},
	{
		path: `${navPages.admin.subPages.connector.form.to}/:id`,
		element: <ConnectorForm />,
		roles: authRole.admin,
	},
	{
		path: navPages.admin.subPages.billing.list.to,
		element: <BillingList />,
		roles: authRole.admin,
	},
	{
		path: `${navPages.admin.subPages.billing.form.to}/:id`,
		element: <BillingForm />,
		roles: authRole.admin,
	},
	{
		path: navPages.attributionOverview.to,
		element: <AttributionOverviewPage />,
	},
	{
		path: navPages.websiteTraffic.to,
		element: <WebsiteTrafficPage />,
	},
	{
		path: navPages.googleLocal.to,
		element: <GoogleLocalPage />,
	},
	{
		path: navPages.payPerClick.to,
		element: <PayPerClickPage />,
	},
	{
		path: navPages.seoContentMarketing.to,
		element: <Seo />,
	},
	{
		path: navPages.socialMedia.to,
		element: <SocialMediaPage />,
	},
	{
		path: navPages.reviewsRatings.to,
		element: <ReviewsRatingsPage />,
	},
	{
		path: navPages.ottStreaming.to,
		element: <OTTPage />,
	},
	{
		path: navPages.offline.to,
		element: <OfflineLeadsPage />,
	},
	{
		path: navPages.tvRadio.to,
		element: <TVRadio />,
	},
	{
		path: navPages.pixelTracking.list.to,
		element: <HeaderPixel />,
	},
	// {
	// 	path: navPages.pixelTracking.graph.to,
	// 	element: <PixelTracking />,
	// },
	{
		path: `${navPages.pixelTracking.form.to}/:id`,
		element: <TrackerForm />,
	},

	/**
	 *b Akomplice::END
	 */
	{ path: navPages.UnderConstruction.to, element: <UnderConstructionPage /> },
	{ path: authPages.loginPage.to, element: <LoginPage /> },
	{ path: authPages.loginPage.to, element: <LoginPage /> },
	{ path: authPages.profilePage.to, element: <ProfilePage /> },
	{ path: authPages.signUpPage.to, element: <SignUpPage /> },

	{ path: '*', element: <NotFoundPage /> },
];

export default contentRoutes;
