import * as React from "react";
import type { SVGProps } from "react";
const SvgCustomFacebookAngry = (props: SVGProps<SVGSVGElement>) => <svg
xmlns='http://www.w3.org/2000/svg'
x={props.x}
y={props.y}
width='16'
height='16'
fill='none'
id='angry'>
<path fill='url(#a)' d='M16 8A8 8 0 1 1-.001 8 8 8 0 0 1 16 8' />
<path
    fill='#000'
    d='M5.2 13.551c0 .528 1.253.444 2.8.444 1.546 0 2.8.084 2.8-.444 0-.636-1.254-1.051-2.8-1.051-1.547 0-2.8.415-2.8 1.051Z'
    filter='url(#b)'
/>
<path
    fill='url(#c)'
    d='M5.2 13.551c0 .528 1.253.444 2.8.444 1.546 0 2.8.084 2.8-.444 0-.636-1.254-1.051-2.8-1.051-1.547 0-2.8.415-2.8 1.051Z'
/>
<path
    fill='url(#d)'
    d='M3.6 9.831c0-.79.538-1.43 1.2-1.43.663 0 1.2.64 1.2 1.43 0 .33-.093.633-.252.874a.527.527 0 0 1-.318.22c-.15.036-.373.075-.63.075s-.481-.039-.63-.075a.524.524 0 0 1-.318-.22 1.588 1.588 0 0 1-.252-.874Zm6.4 0c0-.79.537-1.43 1.2-1.43.662 0 1.2.64 1.2 1.43 0 .33-.094.633-.252.874a.524.524 0 0 1-.318.22c-.207.05-.418.075-.63.075-.257 0-.48-.039-.63-.075a.53.53 0 0 1-.32-.22 1.596 1.596 0 0 1-.25-.874Z'
/>
<path
    fill='#000'
    d='M3.6 9.831c0-.79.538-1.43 1.2-1.43.663 0 1.2.64 1.2 1.43 0 .33-.093.633-.252.874a.527.527 0 0 1-.318.22c-.15.036-.373.075-.63.075s-.481-.039-.63-.075a.524.524 0 0 1-.318-.22 1.588 1.588 0 0 1-.252-.874Zm6.4 0c0-.79.537-1.43 1.2-1.43.662 0 1.2.64 1.2 1.43 0 .33-.094.633-.252.874a.524.524 0 0 1-.318.22c-.207.05-.418.075-.63.075-.257 0-.48-.039-.63-.075a.53.53 0 0 1-.32-.22 1.596 1.596 0 0 1-.25-.874Z'
    filter='url(#e)'
/>
<path
    fill='#4F4F67'
    d='M4.968 9.333a.33.33 0 0 1 .007.07c0 .202-.176.367-.394.367-.217 0-.393-.165-.393-.366 0-.083.03-.16.08-.221.224.053.46.104.7.15Zm5.927.437c-.211 0-.383-.153-.393-.348.258-.038.515-.085.765-.136.014.038.021.078.02.119 0 .2-.175.365-.393.365Z'
/>
<path
    fill='#000'
    d='M9 7.6c0-.446.163-.6.445-.6.28 0 .414.276.506 1.066 1.128 0 3.038-.534 3.222-.534.178 0 .277.085.317.267.035.158-.023.308-.221.4-.621.287-2.443.935-3.984.935-.168 0-.285-.086-.285-.301V7.6Zm-2.951.466C6.14 7.276 6.275 7 6.555 7c.282 0 .445.154.445.6v1.233c0 .215-.117.301-.285.301-1.541 0-3.363-.648-3.984-.935-.198-.092-.256-.242-.221-.4.04-.182.14-.267.317-.267.184 0 2.094.534 3.222.534Z'
    filter='url(#f)'
/>
<path
    fill='url(#g)'
    d='M9 7.6c0-.446.163-.6.445-.6.28 0 .414.276.506 1.066 1.128 0 3.038-.534 3.222-.534.178 0 .277.085.317.267.035.158-.023.308-.221.4-.621.287-2.443.935-3.984.935-.168 0-.285-.086-.285-.301V7.6Zm-2.951.466C6.14 7.276 6.275 7 6.555 7c.282 0 .445.154.445.6v1.233c0 .215-.117.301-.285.301-1.541 0-3.363-.648-3.984-.935-.198-.092-.256-.242-.221-.4.04-.182.14-.267.317-.267.184 0 2.094.534 3.222.534Z'
/>
<defs>
    <linearGradient id='a' x1='8' x2='8' y2='10.751' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#E04300' />
        <stop offset='1' stopColor='#FFA320' />
    </linearGradient>
    <linearGradient
        id='c'
        x1='8'
        x2='8'
        y1='12.703'
        y2='14'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#3D0D00' />
        <stop offset='1' stopColor='#661C04' />
    </linearGradient>
    <linearGradient
        id='d'
        x1='8'
        x2='8'
        y1='8.4'
        y2='11'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#191A33' />
        <stop offset='.872' stopColor='#3B426A' />
    </linearGradient>
    <linearGradient
        id='g'
        x1='11.615'
        x2='11.615'
        y1='9.333'
        y2='7'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#9A2F00' />
        <stop offset='1' stopColor='#D44800' />
    </linearGradient>
    <filter
        id='b'
        width='7.6'
        height='3.5'
        x='4.2'
        y='12.5'
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='1' />
        <feGaussianBlur stdDeviation='.5' />
        <feColorMatrix values='0 0 0 0 1 0 0 0 0 0.509681 0 0 0 0 0 0 0 0 0.371207 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
    </filter>
    <filter
        id='e'
        width='8.8'
        height='2.6'
        x='3.6'
        y='8.4'
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='.5' />
        <feComposite in2='hardAlpha' k2='-1' k3='1' operator='arithmetic' />
        <feColorMatrix values='0 0 0 0 0.0387428 0 0 0 0 0.0406183 0 0 0 0 0.0875053 0 0 0 1 0' />
        <feBlend in2='shape' result='effect1_innerShadow' />
    </filter>
    <filter
        id='f'
        width='11.199'
        height='2.834'
        x='2.4'
        y='7'
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='.6' />
        <feGaussianBlur stdDeviation='.05' />
        <feColorMatrix values='0 0 0 0 0.565875 0 0 0 0 0.151272 0 0 0 0 0 0 0 0 0.15024 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
    </filter>
</defs>
</svg>;
export default SvgCustomFacebookAngry;