/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import {
	SortingState,
	createColumnHelper,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
	ColumnFiltersState,
	FilterFn,
} from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { rankItem } from '@tanstack/match-sorter-utils';
import { toast } from 'react-toastify';
import Container from '../../components/layouts/Container/Container';
import Subheader, {
	SubheaderLeft,
	SubheaderRight,
} from '../../components/layouts/Subheader/Subheader';
import TableTemplate from '../../templates/common/TableParts.template';
import { navPages } from '../../config/pages.config';
import Card, { CardBody, CardHeader, CardHeaderChild, CardTitle } from '../../components/ui/Card';
import Badge from '../../components/ui/Badge';
import Button from '../../components/ui/Button';
import FieldWrap from '../../components/form/FieldWrap';
import Icon from '../../components/icon/Icon';
import Input from '../../components/form/Input';
import PageWrapper from '../../components/layouts/PageWrapper/PageWrapper';
import { ITracker } from '../../api/types';
import { apiPixelTracking } from '../../api/pixel-tracking/apiPixelTracking';
import handleError from '../../utils/errorHandling';
import LoaderDotsCommon from '../../components/LoaderDots.common';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/ui/Modal';
import TrackerForm from './Tracker.Form.page';

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	// Rank the item
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
	const itemRank = rankItem(row.getValue(columnId), value);

	// Store the itemRank info
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	addMeta({ itemRank });

	// Return if the item should be filtered in/out
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
	return itemRank?.passed;
};
const editLinkPath = `../${navPages.pixelTracking.form.to}/`;
const UserList = () => {
	const [trackers, setTrackers] = useState<ITracker[]>([]);
	const [deleteID, setDeleteID] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [sorting, setSorting] = useState<SortingState>([]);
	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
	const [globalFilter, setGlobalFilter] = React.useState('');
	const columnHelper = createColumnHelper<ITracker>();
	const columns = [
		columnHelper.accessor('name', {
			cell: (info) => <span>{info.getValue()}</span>,
			header: 'Name',
			enableColumnFilter: true,
		}),
		columnHelper.accessor('description', {
			cell: (info) => <span>{info.getValue()}</span>,
			header: 'Description',
		}),
		columnHelper.accessor('website_name', {
			cell: (info) => <span>{info.getValue()}</span>,
			header: 'Website Name',
		}),
		columnHelper.accessor('website_url', {
			cell: (info) => <span>{info.getValue()}</span>,
			header: 'Website URL',
		}),
		columnHelper.accessor('id', {
			cell: (info) => (
				<>
					<Link to={`${editLinkPath}${info.row.original.id}`}>
						<Button variant='default' icon='HeroPencil' />
					</Link>
					<Button
						variant='default'
						icon='HeroTrash'
						onClick={() => showDeleteModal(info.row.original.id)}
					/>
				</>
			),
			header: 'Actions',
			id: 'Actions',
			enableSorting: false,
		}),
	];

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (!showModal) {
			setDeleteID('');
		}
	}, [showModal]);

	const table = useReactTable({
		data: trackers,
		columns,
		filterFns: {
			fuzzy: fuzzyFilter,
		},
		state: {
			sorting,
			columnFilters,
			globalFilter,
		},
		globalFilterFn: fuzzyFilter,
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		initialState: {
			pagination: { pageSize: 5 },
		},
	});

	function showDeleteModal(id: string) {
		setDeleteID(id);
		setShowModal(true);
	}

	function fetchData() {
		apiPixelTracking
			.getTrackers()
			.then((x) => {
				setTrackers(x?.data?.data as ITracker[]);
			})
			.catch(handleError)
			.finally(() => {
				setIsLoading(false);
			});
	}

	function handleRemove() {
		if (deleteID) {
			setIsLoading(true);
			apiPixelTracking
				.deletePixelTrackerById(deleteID)
				.then((x) => {
					toast.success('Tracker Created successful!');
					fetchData();
				})
				.catch(handleError)
				.finally(() => {
					setIsLoading(false);
					setShowModal(false);
				});
		}
	}

	if (isLoading) {
		return (
			<div className='flex h-full w-full flex-col items-center justify-center'>
				<LoaderDotsCommon />
			</div>
		);
	}

	return (
		<PageWrapper name={navPages.pixelTracking.list.text}>
			{/* <Container className='flex h-full w-full items-center justify-center'> */}
			<Card className='m-5 h-full '>
				<CardHeader>
					<CardHeaderChild>
						<CardTitle>Trackers List</CardTitle>
						<Badge
							variant='outline'
							className='border-transparent px-4'
							rounded='rounded-full'>
							{table.getFilteredRowModel().rows.length} items
						</Badge>
					</CardHeaderChild>

					<Subheader>
						<SubheaderLeft>
							<FieldWrap
								firstSuffix={<Icon className='mx-2' icon='HeroMagnifyingGlass' />}
								lastSuffix={
									globalFilter && (
										<Icon
											icon='HeroXMark'
											color='red'
											className='mx-2 cursor-pointer'
											onClick={() => {
												setGlobalFilter('');
											}}
										/>
									)
								}>
								<Input
									id='example'
									name='example'
									placeholder='Search...'
									value={globalFilter ?? ''}
									onChange={(e) => setGlobalFilter(e.target.value)}
								/>
							</FieldWrap>
						</SubheaderLeft>
						<SubheaderRight>
							<Link to={`${editLinkPath}new`}>
								<Button variant='solid' icon='HeroPlus'>
									New Tracker
								</Button>
							</Link>
						</SubheaderRight>
					</Subheader>
				</CardHeader>
				<CardBody className='w-full overflow-auto'>
					<TableTemplate
						className='table-fixed max-md:min-w-[70rem]'
						table={table}
						hasFooter={false}
					/>
				</CardBody>
			</Card>
			{/* </Container> */}

			<Modal setIsOpen={setShowModal} isOpen={showModal} isCentered>
				<ModalHeader>Confirm</ModalHeader>
				<ModalBody>Are you sure you want to delete?</ModalBody>
				<ModalFooter>
					<div className='flex w-full justify-end'>
						<Button onClick={() => handleRemove()} variant='solid' color='red'>
							Confirm
						</Button>
						<Button onClick={() => setShowModal(false)}>Cancel</Button>
					</div>
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};
export default UserList;
