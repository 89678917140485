/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosConfig, IConfig } from '../types';
import createAxiosInstance from './axiosInstance';

const apiUrl = process.env.REACT_APP_API_URL || '';

const axiosInstance = createAxiosInstance(apiUrl);

function setToken(token: string) {
	axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const fetchAll = (resource: string, config: AxiosConfig = {}) => {
	return axiosInstance.get(`/${resource}`, config);
};

const fetchOne = (resource: string, id: string, config: AxiosConfig = {}) => {
	return axiosInstance.get(`/${resource}/${id}`, config);
};

const post = (resource: string, data: unknown = {}, config: AxiosConfig = {}) => {
	return axiosInstance.post(`/${resource}`, data, config);
};

const put = (resource: string, data: unknown = {}, options: IConfig = {}) => {
	const resourceUrl = options?.resourceID ? `${resource}/${options.resourceID}` : resource;
	return axiosInstance.put(`/${resourceUrl}`, data, options.config);
};

const patch = (resource: string, data: unknown = {}, options: IConfig = {}) => {
	const resourceUrl = options?.resourceID ? `${resource}/${options.resourceID}` : resource;
	return axiosInstance.patch(`/${resourceUrl}`, data, options.config);
};

const remove = (resource: string, id: string, config: AxiosConfig = {}) => {
	const resourceUrl = `${resource}/${id}`;
	return axiosInstance.delete(`/${resourceUrl}`, config);
};

const apiProvider = {
	fetchAll,
	fetchOne,
	post,
	put,
	patch,
	remove,
	setToken,
};

export default apiProvider;
