/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC } from 'react';
import ChartContainer from '../helper/ChartContainer';
import './style.css';
import BarChart from '../charts/Barchart';
import HorizontalBarChart from '../charts/HorizontalBarChart';
import GeoChart from '../charts/GeoChart';
import LoaderDotsCommon from '../LoaderDots.common';

interface IBarChart {
	data: any[];
	mapData: any[];
	title: string;
	icon: string;
	xDataKey: string;
	dataKey: string;
	isHorizontal?: boolean;
	color?: string;
	barDataKeys?: string[];
	height?: number;
	options?: any;
	isSampleData?: boolean;
	isLoading?: boolean;
	isComparisonChart?: boolean;
	onExport?: (exportType: 'csv' | 'pdf', fileName: string, fields: string) => void;
	exportFileName?: string;
	exportFields?: string;
	id?: string;
}

const BarMapChartContainer: FC<IBarChart> = ({
	data,
	icon,
	title,
	xDataKey,
	dataKey,
	isHorizontal,
	color,
	barDataKeys,
	height,
	options,
	mapData,
	isSampleData,
	isLoading,
	onExport,
	exportFileName,
	exportFields,
	id = 'idd',
	isComparisonChart = false,
}) => {
	function onExportWrapper(exportType: 'csv' | 'pdf') {
		if (onExport && exportFileName && exportFields) {
			onExport(exportType, exportFileName, exportFields);
		}
	}

	let chartContent;
	if (isComparisonChart) {
		chartContent = (
			<div
				style={{ height }}
				className='flex h-full w-full flex-col items-center justify-center'>
				Date Comparison Chart not available
			</div>
		);
	} else if (isLoading) {
		chartContent = (
			<div className='flex h-full w-full flex-col items-center justify-center'>
				<LoaderDotsCommon />
			</div>
		);
	} else {
		chartContent = (
			<div className='flex w-full' id={id}>
				<div style={{ width: '25%' }}>
					{isHorizontal ? (
						<HorizontalBarChart
							data={data}
							dataKey={dataKey}
							nameDataKey={xDataKey}
							color='#3b82f6'
							height={height}
						/>
					) : (
						<BarChart
							data={data}
							dataKeys={barDataKeys}
							xDataKey={xDataKey}
							yDataKey={dataKey}
							color={color}
							height={height}
						/>
					)}
				</div>
				<div style={{ width: '75%' }}>
					<GeoChart data={mapData} options={options} height={300} />
				</div>
			</div>
		);
	}

	return (
		<ChartContainer
			isSampleData={isSampleData}
			title={title}
			icon={icon}
			onExportWrapper={onExportWrapper}>
			{chartContent}
		</ChartContainer>
	);
};

BarMapChartContainer.defaultProps = {
	isHorizontal: false,
	color: '#3b82f6',
	barDataKeys: [],
	height: 220,
	options: {},
	isSampleData: true,
	isLoading: false,
	isComparisonChart: false,
	onExport: () => {},
	exportFields: '',
	exportFileName: '',
};

export default BarMapChartContainer;
