import { IOptions, PromiseAxiosResponse } from '../types';
import ApiCore from '../utils/apiCore';
import apiProvider from '../utils/apiProvider';

const url = 'users';
const plural = '';
const single = '';

class ApiUser extends ApiCore {
	getCurrentUser: () => PromiseAxiosResponse;

	constructor(params: IOptions) {
		super(params);

		this.getCurrentUser = () => {
			const resource = '/me';
			return apiProvider.fetchAll(`${url}${resource}`);
		};
	}
}

export const apiUser = new ApiUser({
	fetchAll: false,
	fetchOne: false,
	post: false,
	put: false,
	patch: false,
	delete: false,
	url,
	plural,
	single,
});

export interface IUser {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
	address: string;
	is_active: boolean;
	is_superuser: boolean;
	is_verified: boolean;
	client_id: string;
}
