/* eslint-disable */
import React, { useState } from 'react';
import shortid from 'shortid';

interface Keyword {
    keyword: string;
    position: number;
    volume: number;
    change: number;
}

const KeywordRanking = () => {
    const keywordsData: Keyword[] = [
        { keyword: 'Keyword 1', position: 5, volume: 1000, change: 10 },
        { keyword: 'Keyword 2', position: 8, volume: 800, change: -5 },
        { keyword: 'Keyword 3', position: 12, volume: 600, change: 15 },
        { keyword: 'Keyword 1', position: 5, volume: 1000, change: 10 },
        { keyword: 'Keyword 2', position: 8, volume: 800, change: -5 },
        { keyword: 'Keyword 3', position: 12, volume: 600, change: 15 },
        { keyword: 'Keyword 1', position: 5, volume: 1000, change: 10 },
        { keyword: 'Keyword 2', position: 8, volume: 800, change: -5 },
        { keyword: 'Keyword 3', position: 12, volume: 600, change: 15 },
        { keyword: 'Keyword 1', position: 5, volume: 1000, change: 10 },
        { keyword: 'Keyword 2', position: 8, volume: 800, change: -5 },
        { keyword: 'Keyword 3', position: 12, volume: 600, change: 15 },
        { keyword: 'Keyword 1', position: 5, volume: 1000, change: 10 },
        { keyword: 'Keyword 2', position: 8, volume: 800, change: -5 },
        { keyword: 'Keyword 3', position: 12, volume: 600, change: 15 },
        { keyword: 'Keyword 1', position: 5, volume: 1000, change: 10 },
        { keyword: 'Keyword 2', position: 8, volume: 800, change: -5 },
        { keyword: 'Keyword 3', position: 12, volume: 600, change: 15 },
    ];

    const itemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState<number>(1);

    const indexOfLastItem: number = currentPage * itemsPerPage;
    const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;
    const currentKeywords: Keyword[] = keywordsData.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className='keyword-ranking p-1 w-full'>
            <table className='w-full'>
                <thead>
                    <tr>
                        <th className='w-3/5'>Keyword</th>
                        <th className='w-1/5'>Position</th>
                        <th className='w-1/5'>Volume</th>
                    </tr>
                </thead>
                <tbody>
                    {currentKeywords.map((keyword: Keyword, index: number) => (
                        <tr key={shortid.generate()}>
                            <td className='w-3/5'>{keyword.keyword}</td>
                            <td className='w-1/5 text-center'>
                                {keyword.position}
                                <sup
                                    className={
                                        keyword.change >= 0 ? 'text-green-500' : 'text-red-500'
                                    }>
                                    {keyword.change}%
                                </sup>
                            </td>
                            <td className='w-1/5 text-center'>
                                {keyword.volume} 
                                <sup
                                    className={
                                        keyword.change >= 0 ? 'text-green-500' : 'text-red-500'
                                    }>
                                    {keyword.change}%
                                </sup>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='pagination mt-1 flex justify-end'>
                <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className='mr-2 text-white'>
                    &larr;
                </button>
                <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentKeywords.length < itemsPerPage}
                    className='text-white'>
                     &rarr;
                </button>
            </div>
        </div>
    );
};

export default KeywordRanking;