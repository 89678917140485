/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import {
	LineChart as LineChartWrapper,
	Line,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ResponsiveContainer,
	DotProps,
} from 'recharts';
import shortid from 'shortid';
import useDarkMode from '../../hooks/useDarkMode';
import { getToolTipStyles } from './utils';
import ComparisonToolTip from './ComparisonToolTip';
import { CustomFacebookLove } from '../icon/svg-icons';
import { ReactComponent as FacebookHaha } from '../icon/svg-icons/facebook-reactions/facebook-reaction-haha.svg';
import { ReactComponent as FacebookWow } from '../icon/svg-icons/facebook-reactions/facebook-wow.svg';
import { ReactComponent as FacebookAngry } from '../icon/svg-icons/facebook-reactions/facebook-angry.svg';
import { ReactComponent as FacebookSad } from '../icon/svg-icons/facebook-reactions/facebook-sad.svg';
import { ReactComponent as FacebookLike } from '../icon/svg-icons/facebook-reactions/blue-thumbs-up-11248.svg';

interface LineChartProps {
	data: any[];
	dataKeys?: string[];
	width?: string | number;
	height?: number;
	xDataKey?: string;
	yDataKey?: string;
	color?: string;
	isComparisonChart?: boolean;
}

interface CustomizedDotProps extends DotProps {
	// eslint-disable-next-line react/require-default-props
	payload?: any;
	dataKey?: string;
	value?: number;
}

const CustomizedDot: React.FC<CustomizedDotProps> = ({ cx = 0, cy = 0, dataKey, value }) => {
	let dot;
	if (value) {
		const lowerKey = dataKey?.toLowerCase() ?? '';
		switch (true) {
			case lowerKey.includes('wow'):
				dot = <FacebookWow height={20} width={20} x={cx - 10} y={cy - 10} />;
				break;
			case lowerKey.includes('anger'):
				dot = <FacebookAngry height={20} width={20} x={cx - 10} y={cy - 10} />;
				break;
			case lowerKey.includes('like'):
				dot = <FacebookLike height={20} width={20} x={cx - 10} y={cy - 10} />;
				break;
			case lowerKey.includes('love'):
				dot = <CustomFacebookLove height={20} width={20} x={cx - 10} y={cy - 10} />;
				break;
			case lowerKey.includes('sorry'):
				dot = <FacebookSad height={20} width={20} x={cx - 10} y={cy - 10} />;
				break;
			case lowerKey.includes('haha'):
				dot = <FacebookHaha height={20} width={20} x={cx - 10} y={cy - 10} />;
				break;
			default:
				return 'Unknown Reaction';
		}
	}
	return dot;
};

const LineChart: React.FC<LineChartProps> = ({
	data,
	width,
	height,
	dataKeys = [],
	xDataKey,
	yDataKey,
	color,
	isComparisonChart,
}) => {
	const { isDarkTheme } = useDarkMode();
	const isPostReaction = yDataKey === 'POST_REACTIONS';
	const fbReactionColor: { [key: string]: string } = {
		Like: '#1877F2', // Brighter blue
		Love: 'pink',
		Haha: 'yellow',
		Wow: '#ffa500', // Brighter orange
		Sorry: '#78828f', // Brighter gray
		Anger: '#ff3333', // Brighter red
	};
	const myColors = ['#3bf658', '#3b82f6', '#55bab6', '#ff7300', '#9C27B0', '#3F51B5', '#FFC107'];
	const renderLines = () => {
		if (dataKeys.length > 1) {
			return dataKeys.map((key, index) => {
				const strokeColor = isPostReaction
					? fbReactionColor[key]
					: myColors[index % myColors.length];
				return (
					<Line
						key={shortid.generate()}
						type='monotone'
						dataKey={key}
						stroke={strokeColor}
						strokeDasharray={key.includes('compare') ? '5.5' : undefined}
						dot={isPostReaction ? <CustomizedDot /> : undefined}
					/>
				);
			});
		}
		return <Line type='monotone' dataKey={yDataKey} stroke={color} activeDot={{ r: 8 }} />;
	};

	return (
		<ResponsiveContainer width={width} height={height}>
			<LineChartWrapper
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}>
				{!isComparisonChart && (
					<XAxis
						dataKey={xDataKey}
						tickFormatter={(x: string) => {
							const date = new Date(x);
							return Number.isNaN(date.getTime())
								? x
								: date.toLocaleDateString('en-US', {
										month: 'short',
										day: 'numeric',
									});
						}}
					/>
				)}
				<YAxis />
				<Tooltip
					contentStyle={getToolTipStyles(isDarkTheme)}
					content={isComparisonChart ? <ComparisonToolTip /> : undefined}
				/>
				{!isComparisonChart && <Legend />}
				{renderLines()}
			</LineChartWrapper>
		</ResponsiveContainer>
	);
};

LineChart.defaultProps = {
	width: '100%',
	height: 300,
	xDataKey: 'name',
	color: '#007bff',
	yDataKey: '',
	dataKeys: [],
	isComparisonChart: false,
};

export default LineChart;
