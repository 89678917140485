/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Button from '../components/ui/Button';
import { useAuth } from '../context/authContext';
import Input from '../components/form/Input';
import LogoTemplate from '../templates/layouts/Logo/Logo.template';
import FieldWrap from '../components/form/FieldWrap';
import Icon from '../components/icon/Icon';
import Validation from '../components/form/Validation';
import { ILogin } from '../api';
import { authPages } from '../config/pages.config';

const LoginPage = () => {
	const { onLogin } = useAuth();

	const [passwordShowStatus, setPasswordShowStatus] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validate: (values: ILogin) => {
			const errors: Partial<ILogin> = {};

			if (!values.email) {
				errors.email = 'Required';
			}

			if (!values.password) {
				errors.password = 'Required';
			}

			return errors;
		},
		onSubmit: (values: ILogin, { setFieldError }) => {
			setIsLoading(true);
			onLogin(values)
				.then(() => setIsLoading(false))
				.catch(() => {
					setIsLoading(false);
					toast.error('Invalid email or Password');
					setFieldError('email', 'Invalid email or Password');
					setFieldError('password', 'Invalid email or Password');
				});
		},
	});

	const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
		if (event.key === 'Enter') {
			formik.handleSubmit();
		}
	};

	return (
		<PageWrapper isProtectedRoute={false} className='bg-white dark:bg-inherit' name='Sign In'>
			<div className='container mx-auto flex h-full items-center justify-center'>
				<div className='flex max-w-sm flex-col gap-8'>
					<div className='flex justify-center'>
						<LogoTemplate />
					</div>
					<div>
						<span className='text-2xl font-semibold'>Sign in</span>
					</div>
					<form className='flex flex-col gap-4' onKeyDown={handleKeyDown}>
						<div
							className={classNames({
								'mb-2': !formik.isValid,
							})}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.email}
								invalidFeedback={formik.errors.email}
								validFeedback='Good'>
								<FieldWrap
									firstSuffix={<Icon icon='HeroEnvelope' className='mx-2' />}>
									<Input
										dimension='lg'
										id='email'
										autoComplete='email'
										name='email'
										placeholder='Email'
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div
							className={classNames({
								'mb-2': !formik.isValid,
							})}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.password}
								invalidFeedback={formik.errors.password}
								validFeedback='Good'>
								<FieldWrap
									firstSuffix={<Icon icon='HeroKey' className='mx-2' />}
									lastSuffix={
										<Icon
											className='mx-2 cursor-pointer'
											icon={passwordShowStatus ? 'HeroEyeSlash' : 'HeroEye'}
											onClick={() => {
												setPasswordShowStatus(!passwordShowStatus);
											}}
										/>
									}>
									<Input
										dimension='lg'
										type={passwordShowStatus ? 'text' : 'password'}
										autoComplete='current-password'
										id='password'
										name='password'
										placeholder='Password'
										value={formik.values.password}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div>
							<Button
								size='lg'
								variant='solid'
								className='w-full font-semibold'
								onClick={() => formik.handleSubmit()}>
								{isLoading ? '	Loading...' : 'Sign in'}
							</Button>
						</div>
					</form>
					{/* <div>
						<span className='text-zinc-500'>
							This site is protected by reCAPTCHA and the Google Privacy Policy.
						</span>
					</div> */}
					<div>
						<span className='flex gap-2 text-sm'>
							<span className='text-zinc-400 dark:text-zinc-600'>
								Don’t have an account?
							</span>
							<Link to={authPages.signUpPage.to} className='hover:text-inherit'>
								Sign up
							</Link>
						</span>
					</div>
				</div>
			</div>
			<ToastContainer />
		</PageWrapper>
	);
};

export default LoginPage;
