import React, { FC, HTMLAttributes, useContext } from 'react';
import logo from '../../../assets/akomplice/logo.png';
import logoDark from '../../../assets/akomplice/logo-dark.png';
import logoSmall from '../../../assets/akomplice/logo-small.png';
import logoSmallDark from '../../../assets/akomplice/logo-dark-small.png';
import useAsideStatus from '../../../hooks/useAsideStatus';
import ThemeContext from '../../../context/themeContext';

type ILogoTemplateProps = HTMLAttributes<HTMLOrSVGElement>;
const LogoTemplate: FC<ILogoTemplateProps> = (props) => {
	const { asideStatus } = useAsideStatus();
	const { isDarkTheme } = useContext(ThemeContext);

	const liteModeLogo = (asideStatus ? logo : logoSmall) as string;
	const darkModeLogo = (asideStatus ? logoDark : logoSmallDark) as string;
	const logoToDisplay = isDarkTheme ? darkModeLogo : liteModeLogo;

	return <img alt='Logo' width='198.43' {...props} height='176.06' src={logoToDisplay} />;
};

export default LogoTemplate;
