import React, { FC } from 'react';
import ChartContainer from '../helper/ChartContainer';
import LineChart from '../charts/LineChart';
import PieChart from '../charts/PieChart';
import './style.css';
import { IBaseChartData } from './seoType';

interface IPieLineChart {
	pieData: IBaseChartData[];
	lineData: IBaseChartData[];
	title: string;
	icon: string;
	dataKeys: string[];
}
const PieLineChart: FC<IPieLineChart> = ({ pieData, lineData, icon, title, dataKeys }) => {
	return (
		<ChartContainer title={title} icon={icon}>
			<div className='flex h-64 w-full'>
				<div className='h-full w-1/3'>
					<PieChart chartData={pieData} />
				</div>
				<div className='w-2/3'>
					<LineChart dataKeys={dataKeys} data={lineData} width='100%' height={220} />
				</div>
			</div>
		</ChartContainer>
	);
};

export default PieLineChart;
