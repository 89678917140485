import React from 'react';
// import UserTemplate from '../../User/User.template';

const DefaultHeaderRightCommon = () => {
	return (
		<>
			{/* <MessagesPartial /> */}
			{/* <NotificationPartial /> */}
			{/* <SettingsPartial /> */}
			{/* <LanguageSelectorPartial /> */}
			{/* <UserTemplate /> */}
		</>
	);
};

export default DefaultHeaderRightCommon;
