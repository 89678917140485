/* eslint-disable react/require-default-props */
import React from 'react';
import {
	BarChart,
	Bar,
	LineChart,
	Line,
	AreaChart,
	Area,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import shortid from 'shortid';
import useDarkMode from '../../hooks/useDarkMode';
import { getToolTipStyles } from './utils';
import ComparisonToolTip from './ComparisonToolTip';
import { myColors } from '../../utils/common';

interface ChartProps {
	data: any[];
	color?: string;
	xDataKey: string;
	dataKeys: string[];
	type: 'bar' | 'line' | 'area';
	isComparisonChart?: boolean;
}

const DynamicChart: React.FC<ChartProps> = ({
	data,
	color = '',
	xDataKey,
	dataKeys,
	type,
	isComparisonChart = false,
}) => {
	const { isDarkTheme } = useDarkMode();
	const renderChartComponent = () => {
		return (
			<>
				{!isComparisonChart && <XAxis dataKey={xDataKey} />}
				<YAxis />
				<Tooltip
					contentStyle={getToolTipStyles(isDarkTheme)}
					content={isComparisonChart ? <ComparisonToolTip /> : undefined}
				/>
				<Legend />
			</>
		);
	};

	const renderChart = () => {
		const commonProps = {
			data,
			margin: { top: 5, right: 30, left: 20, bottom: 5 },
		};
		const baseElementProps = {
			fill: color || myColors[0],
			strokeDasharray: '3 3',
		};

		switch (type) {
			case 'bar':
				return (
					<BarChart data={commonProps.data} margin={commonProps.margin}>
						{dataKeys?.length > 1 ? (
							dataKeys.map((key, index) => (
								<Bar
									key={shortid.generate()}
									dataKey={key}
									fill={myColors[index % myColors.length]}
									stackId='a'
								/>
							))
						) : (
							<Bar dataKey={dataKeys[0]} fill={baseElementProps.fill} />
						)}
						{renderChartComponent()}
					</BarChart>
				);
			case 'line':
				return (
					<LineChart data={commonProps.data} margin={commonProps.margin}>
						{dataKeys?.length > 1 ? (
							dataKeys.map((key, index) => (
								<Line
									key={shortid.generate()}
									dataKey={key}
									stroke={myColors[index % myColors.length]}
									type='monotone'
									activeDot={{ r: 5 }}
									strokeDasharray={key.includes('compare') ? '5.5' : undefined}
								/>
							))
						) : (
							<Line
								dataKey={dataKeys[0]}
								fill={baseElementProps.fill}
								type='monotone'
								stroke={baseElementProps.fill}
								activeDot={{ r: 8 }}
							/>
						)}
						{renderChartComponent()}
					</LineChart>
				);
			case 'area':
				return (
					<AreaChart data={commonProps.data} margin={commonProps.margin}>
						{dataKeys?.length > 1 ? (
							dataKeys.map((key, index) => (
								<Area
									type='monotone'
									dataKey={key}
									stroke={myColors[index % myColors.length]}
									fill={myColors[index % myColors.length]}
									activeDot={{ r: 5 }}
									dot={{ r: 4 }}
									strokeDasharray={key.includes('compare') ? '5.5' : undefined}
									key={shortid.generate()}
								/>
							))
						) : (
							<Area
								type='monotone'
								dataKey={dataKeys[0]}
								stroke={baseElementProps.fill}
								fill={baseElementProps.fill}
								activeDot={{ r: 5 }}
								dot={{ r: 4 }}
							/>
						)}
						{renderChartComponent()}
					</AreaChart>
				);
			default:
				return <>Something went wrong!</>;
		}
	};

	return (
		<ResponsiveContainer width='100%' height='100%'>
			{renderChart()}
		</ResponsiveContainer>
	);
};

export default DynamicChart;
