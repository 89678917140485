import shortid from 'shortid';
import BarMapChartContainer from '../../components/chart_containers/BarMapChartContainer';
import DynamicChartContainer from '../../components/chart_containers/DynamicChartContainer';
import { TChartType } from '../../components/helper/ChartContainer';
import { getFormattedDate } from '../../utils/common';
import { useDateRange } from '../../context/dateRangeContext';

const PixelTracking = () => {
	const mapData: Array<Array<string | number>> = [
		['Country', 'Popularity'],
		['Brazil', 200],
		['United States', 300],
		['United Kingdom', 400],
		['Russia', 500],
		['Italy', 600],
		['Spain', 700],
	];

	const sortedData = [
		mapData[0],
		...mapData.slice(1).sort((a, b) => (b[1] as number) - (a[1] as number)),
	];

	const top10Countries = sortedData.slice(0, 10);

	const generateDummyYTData = () => {
		const dates = [
			'2024-06-01',
			'2024-06-02',
			'2024-06-03',
			'2024-06-04',
			'2024-06-05',
			'2024-06-06',
			'2024-06-07',
			'2024-06-08',
			'2024-06-09',
			'2024-06-10',
		];

		return dates.map((date) => ({
			date,
			'Outbound Click': Math.floor(Math.random() * 1000) + 100, // Random views between 100 and 1100
			Reffer: Math.floor(Math.random() * 500) + 50, // Random red views between 50 and 550
			'user agent': (Math.random() * 100).toFixed(2), // Random percentage between 0 and 100
			'New Viewers': Math.floor(Math.random() * 300) + 20, // Random new viewers between 20 and 320
		}));
	};

	const ytData = generateDummyYTData();

	const chartData = [
		{
			data: ytData,
			title: 'Outbound Click',
			icon: 'HeroDevicePhoneMobile',
			dataKey: 'Outbound Click',
			color: '#3fbe65',
			xDataKey: 'date',
			isSampleData: false,
			isLoading: false,
			dataKeys: ['Outbound Click'],
			showTopLegends: true,
			isTopLegendAvg: true,
			// isComparisonChart,
			defaultChartType: 'bar',
			exportFields: 'Outbound Click',
			isViewEnabled: true,
			// url: SelectedVideos?.length > 0 ? 'video-analytics' : 'youtube-analytics',
			// ExportFunction: handleExport,
		},
		{
			component: 'AreaChartContainer',
			data: ytData,
			title: 'Reffer',
			icon: 'HeroDevicePhoneMobile',
			dataKey: 'Reffer',
			color: '#3fbe65',
			xDataKey: 'date',
			isSampleData: false,
			isLoading: false,
			dataKeys: ['Reffer'],
			showTopLegends: true,
			isTopLegendAvg: true,
			isViewEnabled: true,
			// isComparisonChart,
			defaultChartType: 'area',
			exportFields: 'red_views',
			// isViewEnabled: SelectedVideos.length > 0,
			url: 'video-analytics',
			// ExportFunction: handleExport,
		},
		{
			component: 'LineChartWithLegendsTop',
			title: 'User Agent',
			icon: 'HeroDevicePhoneMobile',
			data: ytData,
			dataKeys: ['user agent'],
			xDataKey: 'date',
			isSampleData: false,
			height: 190,
			isLoading: false,
			// isComparisonChart,
			isViewEnabled: true,
			defaultChartType: 'line',
			showTopLegends: true,
			exportFields: 'average_view_percentage',
			// isViewEnabled: SelectedVideos.length > 0,
			url: 'video-analytics',
			// ExportFunction: handleExport,
		},
		{
			component: 'BarChartContainerTopLegends',
			data: ytData,
			title: 'Location',
			icon: 'HeroDevicePhoneMobile',
			dataKey: 'New Viewers',
			color: '#ffc658',
			xDataKey: 'date',
			isSampleData: false,
			isLoading: false,
			dataKeys: ['New Viewers'],
			showTopLegends: true,
			isViewEnabled: true,
			isTopLegendAvg: true,
			// isComparisonChart,
			defaultChartType: 'bar',
			exportFields: 'new_viewers',
			// isViewEnabled: SelectedVideos?.length <= 0,
			url: '',
			ExportFunction: undefined,
		},
	];
	const { fromDate, toDate, compareFromDate, compareToDate, isCompare } = useDateRange();

	const formattedDate = getFormattedDate(new Date(fromDate), new Date(toDate));

	return (
		<div className='m-5'>
			<div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
				<BarMapChartContainer
					data={top10Countries}
					title='Location'
					icon='HeroBeaker'
					dataKey='Location'
					xDataKey='country'
					isHorizontal
					height={300}
					mapData={sortedData}
					isSampleData={false}
					// isLoading={isLoading}
					// isComparisonChart={isCompare}
					// onExport={handleViewExport}
					id='country,country_code,views'
					// exportFields='country,country_code,views'
					// exportFileName={`Views-By-Country - ${formattedDate}`}
				/>
				{chartData
					?.filter((e) => e?.isViewEnabled === true)
					?.map((chart) => (
						<DynamicChartContainer
							id={chart.exportFields ?? shortid.generate()}
							data={chart.data}
							title={chart.title}
							xDataKey={chart.xDataKey}
							dataKeys={chart.dataKeys}
							color={chart?.color}
							isLoading={chart.isLoading}
							isTopLegendAvg={chart.isTopLegendAvg}
							showTopLegends={chart.showTopLegends}
							defaultChartType={chart.defaultChartType as TChartType}
							isSampleData={chart.isSampleData}
							exportFields={chart.exportFields}
							exportFileName={`${chart.title} - ${formattedDate}`}
							isComparisonChart={isCompare}
							key={shortid.generate()}
							icon='HeroBeaker'
							height={300}
							onExport={chart.ExportFunction}
							url={chart.url}
						/>
					))}
			</div>
		</div>
	);
};

export default PixelTracking;
