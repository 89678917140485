import React, { useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../components/layouts/PageWrapper/PageWrapper';
import Button from '../../components/ui/Button';
import Input from '../../components/form/Input';
import LogoTemplate from '../../templates/layouts/Logo/Logo.template';
import FieldWrap from '../../components/form/FieldWrap';
import Icon from '../../components/icon/Icon';
import Validation from '../../components/form/Validation';
import { apiAuth } from '../../api';
import { ISignUp, SignupFormValues as SignUpFormValues, signUpSchema } from './signUp';
import { authPages } from '../../config/pages.config';

const SignUpPage = () => {
	const [passwordShowStatus, setPasswordShowStatus] = useState<boolean>(false);
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: new SignUpFormValues(),
		validationSchema: signUpSchema,
		onSubmit: (values: ISignUp, { setFieldError }) => {
			apiAuth
				.signUp(values)
				.then(() => {
					toast.success('Signup successful');
					setTimeout(() => {
						navigate(authPages.loginPage.to);
					}, 1000);
				})
				.catch((err) => {
					console.log(err);
				});
		},
	});

	return (
		<PageWrapper isProtectedRoute={false} className='bg-white dark:bg-inherit' name='Sign In'>
			<div className='container mx-auto flex h-full items-center justify-center'>
				<div className='flex max-w-sm flex-col gap-8'>
					<div className='flex justify-center'>
						<LogoTemplate />
					</div>
					<div>
						<span className='text-2xl font-semibold'>Sign in</span>
					</div>
					<form className='flex flex-col gap-4' noValidate>
						<div
							className={classNames({
								'mb-2': !formik.isValid,
							})}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.first_name}
								invalidFeedback={formik.errors.first_name}>
								<FieldWrap firstSuffix={<Icon icon='HeroUser' className='mx-2' />}>
									<Input
										dimension='lg'
										id='first_name'
										autoComplete='first_name'
										name='first_name'
										placeholder='First Name'
										value={formik.values.first_name}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div
							className={classNames({
								'mb-2': !formik.isValid,
							})}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.last_name}
								invalidFeedback={formik.errors.last_name}>
								<FieldWrap firstSuffix={<Icon icon='HeroUser' className='mx-2' />}>
									<Input
										dimension='lg'
										id='last_name'
										name='last_name'
										placeholder='Last Name'
										value={formik.values.last_name}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div
							className={classNames({
								'mb-2': !formik.isValid,
							})}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.email}
								invalidFeedback={formik.errors.email}>
								<FieldWrap
									firstSuffix={<Icon icon='HeroAtSymbol' className='mx-2' />}>
									<Input
										dimension='lg'
										id='email'
										name='email'
										placeholder='Email'
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div
							className={classNames({
								'mb-2': !formik.isValid,
							})}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.password}
								invalidFeedback={formik.errors.password}>
								<FieldWrap
									firstSuffix={<Icon icon='HeroKey' className='mx-2' />}
									lastSuffix={
										<Icon
											className='mx-2 cursor-pointer'
											icon={passwordShowStatus ? 'HeroEyeSlash' : 'HeroEye'}
											onClick={() => {
												setPasswordShowStatus(!passwordShowStatus);
											}}
										/>
									}>
									<Input
										dimension='lg'
										type={passwordShowStatus ? 'text' : 'password'}
										id='password'
										name='password'
										placeholder='Password'
										value={formik.values.password}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div>
							<Button
								size='lg'
								variant='solid'
								className='w-full font-semibold'
								onClick={() => formik.handleSubmit()}>
								Sign in
							</Button>
						</div>
					</form>
					<div>
						<span className='flex gap-2 text-sm'>
							<span className='text-zinc-400 dark:text-zinc-600'>
								Don’t have an account?
							</span>
							<Link to='/' className='hover:text-inherit'>
								Sign up
							</Link>
						</span>
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export default SignUpPage;
