/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, {
	createContext,
	FC,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import { authPages, navPages } from '../config/pages.config';
import { TUser, TUserMe } from '../mocks/db/users.db';
import { apiAuth, ILogin, apiUser } from '../api';

export interface IAuthContextProps {
	usernameStorage: string | ((newValue: string | null) => void) | null;
	onLogin: (loginValues: ILogin) => Promise<void>;
	onLogout: () => void;
	userData: TUser;
	userDataMe: TUserMe;
	isLoading: boolean;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthProviderProps {
	children: ReactNode;
}
export const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
	const [storedUser, setUser] = useLocalStorage('akomplice_user', null);
	const [userData, setUserData] = useState<TUser>();
	const [userDataMe, setUserDataMe] = useState<TUserMe>();
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();
	useEffect(() => {
		if (!userData) {
			if (storedUser) {
				fetchCurrentUser();
			} else {
				// eslint-disable-next-line @typescript-eslint/no-floating-promises, @typescript-eslint/no-use-before-define
				onLogout();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function fetchCurrentUser() {
		await apiUser
			.getCurrentUser()
			.then((res) => {
				setUserData(res.data.data as TUser);
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				setUserDataMe(res.data.data as TUserMe);
			})
			.catch(() => {
				onLogout();
			});
	}

	// call this function when you want to authenticate the user
	const onLogin = async (loginValues: ILogin) => {
		setIsLoading(true);
		const apiResponse = await apiAuth.login(loginValues).finally(() => {
			setIsLoading(false);
		});
		if (apiResponse) {
			setUserData(apiResponse.data as TUser);
			if (typeof setUser === 'function') {
				await setUser(apiResponse.data as string).then(() => {
					fetchCurrentUser().then((x) => {
						navigate(navPages.websiteTraffic.to);
					});
				});
			}
		}
	};

	// call this function to sign out logged-in user
	async function onLogout() {
		if (typeof setUser === 'function') await setUser(null);
		navigate(`../${authPages.loginPage.to}`, { replace: true });
	}

	const value: IAuthContextProps = useMemo(
		() => ({
			usernameStorage: storedUser,
			onLogin,
			onLogout,
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			userData: userData!,
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			userDataMe: userDataMe!,
			isLoading,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[storedUser, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};
