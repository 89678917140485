/* eslint-disable @typescript-eslint/ban-types */
import React, { createContext, useState, useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import { Range } from 'react-date-range';

interface ISelectedDate {
	selection: Range;
	comparison: Range;
}

export interface DateRangeState {
	selectedDate: ISelectedDate;
	setSelectedDate: React.Dispatch<React.SetStateAction<ISelectedDate>>;
	isCompare: boolean;
	setIsCompare: React.Dispatch<React.SetStateAction<boolean>>;
	fromDate: string;
	toDate: string;
	compareFromDate?: string;
	compareToDate?: string;
	isCalenderDisabled?: boolean;
	disableDateRange?: (isDisabled: boolean) => void; // Changed to void return type
}

// todo Remove it later
function formatDate(date: Date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
}

const defaultState: DateRangeState = {
	selectedDate: {
		selection: {
			startDate: dayjs('2024-02-01').toDate(),
			endDate: dayjs('2024-02-29').toDate(),
			key: 'selection',
		},
		comparison: {
			startDate: dayjs().startOf('week').add(-2, 'month').add(-1, 'day').toDate(),
			endDate: dayjs().startOf('week').add(-1, 'month').add(-1, 'day').toDate(),
			key: 'comparison',
		},
	},
	setSelectedDate: () => {},
	isCompare: false,
	setIsCompare: () => {},
	fromDate: formatDate(dayjs().startOf('week').add(-1, 'month').toDate()),
	toDate: formatDate(dayjs().toDate()),
	compareFromDate: formatDate(dayjs().startOf('week').add(-2, 'month').add(-1, 'day').toDate()),
	compareToDate: formatDate(dayjs().startOf('week').add(-1, 'month').add(-1, 'day').toDate()),
	isCalenderDisabled: false,
	disableDateRange: (isDisabled: boolean) => {}, // Dummy implementation
};

const DateRangeContext = createContext<DateRangeState>(defaultState);

export const DateRangeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [selectedDate, setSelectedDate] = useState<ISelectedDate>(defaultState.selectedDate);
	const [isCompare, setIsCompare] = useState(defaultState.isCompare);
	const [isCalenderDisabled, setIsCalenderDisabled] = useState<boolean>(false);

	const { startDate, endDate } = selectedDate.selection;
	const { startDate: compareStartDate, endDate: compareEndDate } = selectedDate.comparison;
	const fromDate = formatDate(startDate as Date);
	const toDate = formatDate(endDate as Date);
	const compareFromDate = formatDate(compareStartDate as Date);
	const compareToDate = formatDate(compareEndDate as Date);

	const value = useMemo(
		() => ({
			selectedDate,
			setSelectedDate,
			isCompare,
			setIsCompare,
			fromDate,
			toDate,
			compareFromDate,
			compareToDate,
			isCalenderDisabled,
			disableDateRange: (isDisabled: boolean) => setIsCalenderDisabled(isDisabled), // Properly update the state
		}),
		[
			selectedDate,
			isCompare,
			fromDate,
			toDate,
			compareFromDate,
			compareToDate,
			isCalenderDisabled,
		],
	);

	return <DateRangeContext.Provider value={value}>{children}</DateRangeContext.Provider>;
};

export const useDateRange = () => useContext(DateRangeContext);
